<template>
  <!-- <HeaderPlugin /> -->
  <div class="ccontainer">
    <!-- Banner Section Starts -->
    <section class="banner-layout-1" id="homepage">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <!-- Header content start -->
            <div class="header-content" data-aos="fade-right">
              <h2 class="mt">
                Revolutionize Your Practice with StellarSync EMR Service
                <!-- Just another #1 EMR | HMS service for your streamline practice -->
              </h2>
              <p style="margin-top: -30px">
                Welcome to the future of healthcare management! StellarSync EMR
                Service is your gateway to efficiency, precision, and seamless
                patient care. Say goodbye to cumbersome paperwork and hello to a
                streamlined, digital solution that empowers your practice to
                thrive in the modern age.
              </p>

              <!-- <div class="btn-video-play">
                <a
                  href="http://www.youtube.com/watch?v=K4wEI5zhHB0"
                  class="popup-video"
                >
                  <div class="btn-play">
                    <figure>
                      <img src="./../assets/images/video-bg.jpg" alt="" />
                    </figure>

                    <i class="flaticon-play-button"></i>
                  </div>

                  <span>Watch the intro video</span>
                </a>
              </div> -->
            </div>
            <!-- Header content end -->
          </div>

          <div class="col-lg-7 mt2">
            <div class="header-img" data-aos="fade-left">
              <img
                src="./../assets/images/header.png"
                alt=""
                style="margin-top: 40px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner Section Ends -->

    <!-- Service box starts -->
    <div class="service-layout-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <!-- service single box start -->
            <div class="service-single" data-aos="fade-up" data-aos-delay="100">
              <div class="icon-box">
                <i class="flaticon-social-network"></i>
              </div>

              <h3>Exclusive Design</h3>
              <div>
                Streamline your practice's operations and boost productivity.
              </div>
              <!-- <a href="#" class="btn-seemore">See More</a> -->
            </div>
            <!-- service single box end -->
          </div>

          <div class="col-lg-3 col-md-6">
            <!-- service single box start -->
            <div class="service-single" data-aos="fade-up" data-aos-delay="200">
              <div class="icon-box">
                <i class="flaticon-settings"></i>
              </div>

              <h3>Accuracy</h3>
              <div>Eliminate errors associated with manual record-keeping.</div>
              <!-- <a href="#" class="btn-seemore">See More</a> -->
            </div>
            <!-- service single box end -->
          </div>

          <div class="col-lg-3 col-md-6">
            <!-- service single box start -->
            <div class="service-single" data-aos="fade-up" data-aos-delay="300">
              <div class="icon-box">
                <i class="flaticon--01-thinking"></i>
              </div>

              <h3>Compliance</h3>
              <div>
                Stay up-to-date with regulatory requirements effortlessly.
              </div>
              <!-- <a href="#" class="btn-seemore">See More</a> -->
            </div>
            <!-- service single box end -->
          </div>

          <div class="col-lg-3 col-md-6">
            <!-- service single box start -->
            <div class="service-single wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
              <div class="icon-box">
                <i class="flaticon-technology"></i>
              </div>

              <h3>Flexibility</h3>
              <div>
                Access patient records anytime, anywhere, from any device.
              </div>
              <!-- <a href="#" class="btn-seemore">See More</a> -->
            </div>
            <!-- service single box end -->
          </div>
        </div>
      </div>
    </div>
    <!-- Service box ends -->

    <!-- welcome quotes section starts -->
    <div class="welcome-layout-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="welcome-box">
              <h2>Welcome to StellaSync healthcare</h2>
              <p>
                Our focus-driven mission is to establish Africa's leading
                AI-powered Electronic Medical Records (EMR) and Hospital
                Management System (HMS) service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- welcome quotes section ends -->

    <!-- How it work section starts -->
    <section class="how-it-work-layout-1" id="howitwork">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h2 class="mt3">How it works</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- How it work tab starts -->
            <div class="how-it-work-tab">
              <!-- How it work tab navigation start -->
              <ul id="tabs" class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    id="tab-A"
                    href="#register"
                    class="nav-link active"
                    data-toggle="tab"
                    role="tab"
                    ><i class="flaticon-login"></i> Automated Payment System
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    id="tab-B"
                    href="#search"
                    class="nav-link"
                    data-toggle="tab"
                    role="tab"
                    ><i class="flaticon-friend-request"></i>Enhance Laboratory
                    and Radiology
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    id="tab-C"
                    href="#friend"
                    class="nav-link"
                    data-toggle="tab"
                    role="tab"
                    ><i class="flaticon-joke"></i> Intelligent Patient
                    Profile</a
                  >
                </li>
              </ul>
              <!-- How it work tab navigation end -->

              <!-- How it work tab content start -->
              <div id="content" class="tab-content" role="tablist">
                <!-- Tab Content start -->
                <div
                  id="register"
                  class="card tab-pane fade show active"
                  role="tabpanel"
                  aria-labelledby="tab-A"
                  data-aos="fade-up"
                >
                  <div class="card-header" role="tab" id="heading-A">
                    <h5 class="mb-0">
                      <a
                        data-toggle="collapse"
                        href="#collapse-A"
                        data-parent="#content"
                        aria-expanded="true"
                        aria-controls="collapse-A"
                      >
                        <i class="flaticon-login"></i>Payment System
                      </a>
                    </h5>
                  </div>

                  <div
                    id="collapse-A"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="heading-A"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="how-it-image">
                            <!-- <img src="./../assets/images/howit.gif" alt="" /> -->
                            <!-- <source src="./../assets/images/howit.mp4" type="video/mp4" style="height:100px;width:100px"> -->
                            <video autoplay loop class="howit_vid">
                              <source
                                src="./../assets/images/howit.mp4"
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </div>

                        <div class="col-md-7">
                          <div class="how-it-content">
                            <h3>Robust Automated Payment System</h3>

                            <p>
                              Our cutting-edge EMR and HMS service is tailored
                              specifically for your unique healthcare pratice.
                              Our solution isn't just about digitizing records;
                              it's about revolutionizing how healthcare is
                              delivered and cost is managed properly.
                            </p>

                            <p>
                              StellaSync is integrated with a robust automated
                              payment system that ensures healthcare providers
                              get paid promptly for the vital services they
                              provide. No more delays, no more hassle with
                              billing and invoicing. It's all taken care of
                              seamlessly. .
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Tab Content end -->

                <!-- Tab Content start -->
                <div
                  id="search"
                  class="card tab-pane" data-aos="fade-up"
                  role="tabpanel"
                  aria-labelledby="tab-B"
                >
                  <div class="card-header" role="tab" id="heading-B">
                    <h5 class="mb-0">
                      <a
                        class="collapsed"
                        data-toggle="collapse"
                        href="#collapse-B"
                        data-parent="#content"
                        aria-expanded="false"
                        aria-controls="collapse-B"
                      >
                        <i class="flaticon-friend-request"></i>Enhance
                        Laboratory and Radiology
                      </a>
                    </h5>
                  </div>

                  <div
                    id="collapse-B"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="heading-B"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="how-it-image">
                            <video autoplay loop class="howit_vid">
                              <source
                                src="./../assets/images/howit2.mp4"
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </div>

                        <div class="col-md-7">
                          <div class="how-it-content">
                            <h3>Enhance Laboratory and Radiology</h3>

                            <p>
                              With our EMR and HMS, healthcare practitioner gain
                              access to an integrated laboratory and radiology
                              feature that's second to none. A centralized
                              platform where laboratory test results and
                              radiology images are seamlessly integrated with
                              patient records.
                            </p>

                            <p>
                              Our laboratory feature enables healthcare
                              professionals to request tests with ease, track
                              sample statuses in real-time, and receive
                              automated alerts when results are ready. This
                              means faster turnaround times for patients and
                              efficient workflows for laboratory staff.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Tab Content end -->

                <!-- Tab Content start -->
                <div
                  id="friend"
                  class="card tab-pane fade"
                  role="tabpanel"
                  aria-labelledby="tab-C"
                >
                  <div class="card-header" role="tab" id="heading-C">
                    <h5 class="mb-0">
                      <a
                        class="collapsed"
                        data-toggle="collapse"
                        href="#collapse-C"
                        data-parent="#content"
                        aria-expanded="false"
                        aria-controls="collapse-C"
                        ><i class="flaticon-joke"></i> Intelligent Patient
                        Profile
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-C"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="heading-C"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="how-it-image">
                            <video autoplay loop class="howit_vid">
                              <source
                                src="./../assets/images/howit3.mp4"
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </div>

                        <div class="col-md-7">
                          <div class="how-it-content">
                            <h3>Intelligent Patient Profile</h3>

                            <p>
                              With our EMR and HMS, healthcare providers can say
                              goodbye to mountains of paperwork and hello to
                              streamlined, efficient workflows. Patient's
                              medical history, from consultations to
                              prescriptions, is securely stored and easily
                              accessible at a click.
                            </p>

                            <p>
                              This means faster diagnosis, better treatment
                              decisions, and ultimately, improved patient
                              outcomes. our solution enables healthcare
                              providers to securely upload, store, and access
                              medical images from anywhere, at any time.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Tab Content end -->
              </div>
              <!-- How it work tab content end -->
            </div>
            <!-- How it work tab ends -->
          </div>
        </div>
      </div>
    </section>
    <!-- How it work section ends -->

    <!-- Features section starts -->
    <section class="features-layout-1 mt">
      <div class="container">
        <div class="row">
          <div class="col-md-6" data-aos="fade-right">
            <!-- Features content start -->
            <div class="feature-content">
              <h4>
                Stand out from the CROWD with our patient-centric mobile app
              </h4>
              <p>
                With StellarSync EMR Service, you're not just keeping up with
                the competition – you're surpassing it. Embrace the future of
                healthcare management and watch your practice thrive like never
                before.
              </p>
              <p>
                Empower your patients with our intuitive mobile app. With easy
                access to their medical records, appointment scheduling, and
                health information, patients can take control of their
                healthcare journey anytime, anywhere.
              </p>
              <p>
                Simplify communication, enhance patient engagement, and elevate
                the quality of care with StellarSync's patient-centric mobile
                app.
              </p>
              <!-- <p>
Ready to take your practice to the next level? Contact us today to schedule a demo of StellarSync EMR Service and experience the difference firsthand. 
Join the countless healthcare professionals who have already made the switch and never look back!
              </p> -->

              <div class="ccenter">
                <a href="#download" class="btn-custom">Download Now</a>
              </div>
            </div>
            <!-- Features content end -->
          </div>

          <div class="col-md-4">
            <!-- Features Image start -->
            <div class="">
              <img
                src="./../assets/images/app1.png"
                class="feature-image apppreimg" data-aos="fade-left"
              />
            </div>
            <!-- Features Image end -->
          </div>
        </div>
      </div>
    </section>
    <!-- Features section ends -->

    <!-- Pricing section starts -->
    <section class="pricing-layout-1" id="pricing">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mt">
            <div class="section-title">
              <h2>Try Three (3) month for free</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="pricing-title wow" data-aos="fade-up">
              <h3>Great price crafted for your healthcare facility!</h3>
              <p>
                StellaSync is the ideal solution for healthcare facilities
                seeking efficient and cost-effective EMR/HMS services.
              </p>

              <p>
                Say goodbye to surprises with our transparent pricing – no
                unexpected fees, guaranteed.
              </p>

              <p>
                StellaSync offers a comprehensive feature set, with no hidden
                charges, no fine print – just straightforward pricing.
              </p>
            </div>

            <div class="ccenter">
              <div class="pricing-tab">
                <ul id="clothing-nav" class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      href="#monthly"
                      id="monthly-tab"
                      role="tab"
                      data-toggle="tab"
                      aria-controls="monthly"
                      aria-expanded="true"
                      >Monthly</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#yearly"
                      role="tab"
                      id="yearly-tab"
                      data-toggle="tab"
                      aria-controls="yearly"
                      >Yearly</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-8">
            <div id="clothing-nav-content" class="tab-content">
              <div
                role="tabpanel"
                class="tab-pane fade show active"
                id="monthly"
                aria-labelledby="monthly-tab"
                data-aos="fade-left"
              >
                <div class="row">
                  <!-- Pricing single start -->
                  <div class="col-md-6">
                    <div class="pricing-box-wrapper">
                      <div class="pricing-box">
                        <div class="pricing-header">
                          <div class="icon-box">
                            <i class="flaticon-sun"></i>
                          </div>

                          <h3>Clinic & Maternity</h3>
                        </div>

                        <div class="pricing-body">
                          <ul>
                            <li>Unlimited patients</li>
                            <li>Unlimited cases</li>
                            <li>Basic insights</li>
                            <li>2-3 team members</li>
                            <li>Up to 5GB disk space</li>
                            <li>Priority support</li>
                          </ul>

                          <h4 class="card_price">₦10k / Month</h4>
                        </div>
                      </div>

                      <div class="btn-buynow">
                        <a href="#">Subscribe Now</a>
                      </div>
                    </div>
                  </div>
                  <!-- Pricing single end -->

                  <!-- Pricing single start -->
                  <div class="col-md-6">
                    <div class="pricing-box-wrapper">
                      <div class="pricing-box">
                        <div class="pricing-header">
                          <div class="icon-box">
                            <i class="flaticon-cityscape"></i>
                          </div>

                          <h3>Hospital</h3>
                        </div>

                        <div class="pricing-body">
                          <ul>
                            <li>Unlimited patients</li>
                            <li>Unlimited cases</li>
                            <li>Advanced insights</li>
                            <li>Smart Lab and Radiology</li>
                            <li>Enhanced staff access</li>
                            <li>Unlimited integrations</li>
                            <li>Unlimited team members</li>
                            <li>Up to 100GB disk space</li>
                            <li>Priority support</li>
                          </ul>

                          <h4 class="card_price">₦25k / Month</h4>
                        </div>
                      </div>

                      <div class="btn-buynow">
                        <a href="#">Subscribe Now</a>
                      </div>
                    </div>
                  </div>
                  <!-- Pricing single end -->
                </div>
              </div>

              <div
                role="tabpanel"
                class="tab-pane fade"
                id="yearly"
                aria-labelledby="yearly-tab"
               
              >
                <div class="row">
                  <!-- Pricing single start -->
                  <div class="col-md-6"  data-aos="zoom-in">
                    <div class="pricing-box-wrapper">
                      <div class="pricing-box pricing-orange">
                        <div class="pricing-header">
                          <div class="icon-box">
                            <i class="flaticon-sun"></i>
                          </div>

                          <h3>Clinic & Maternity</h3>
                        </div>

                        <div class="pricing-body">
                          <ul>
                            <li>Unlimited patients</li>
                            <li>Unlimited cases</li>
                            <li>Basic insights</li>
                            <li>2-3 team members</li>
                            <li>Up to 5GB disk space</li>
                            <li>Priority support</li>
                          </ul>

                          <h4 class="card_price">From ₦114k / Year</h4>
                        </div>
                      </div>

                      <div class="btn-buynow">
                        <a href="#">Subscribe Now</a>
                      </div>
                    </div>
                  </div>
                  <!-- Pricing single end -->

                  <!-- Pricing single start -->
                  <div class="col-md-6"  data-aos="zoom-in">
                    <div class="pricing-box-wrapper">
                      <div class="pricing-box pricing-green">
                        <div class="pricing-header">
                          <div class="icon-box">
                            <i class="flaticon-cityscape"></i>
                          </div>

                          <h3>Hospital</h3>
                        </div>

                        <div class="pricing-body">
                          <ul>
                            <li>Unlimited patients</li>
                            <li>Unlimited cases</li>
                            <li>Advanced insights</li>
                            <li>Smart Lab and Radiology</li>
                            <li>Enhanced staff access</li>
                            <li>Unlimited integrations</li>
                            <li>Unlimited team members</li>
                            <li>Up to 100GB disk space</li>
                            <li>Priority support</li>
                          </ul>

                          <h4 class="card_price">From ₦270k / Year</h4>
                        </div>
                      </div>

                      <div class="btn-buynow">
                        <a href="#">Subscribe Now</a>
                      </div>
                    </div>
                  </div>
                  <!-- Pricing single end -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Pricing section ends -->

    <!-- Testimonial section starts -->
    <section class="testimonial-layout-1" id="testimonial" hidden>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h2>Testimonial</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Testimonial slider starts -->
            <div class="testimonial-slider-wrapper" data-aos="fade-up">
              <div class="swiper-container testimonial-slider">
                <div class="swiper-wrapper">
                  <!-- Testimonial slide start -->
                  <div class="swiper-slide">
                    <div class="testimonial-slide">
                      <figure>
                        <img src="./../assets/images/author-1.jpg" alt="" />
                      </figure>

                      <div class="testimonial-entry">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Testimonial slide end -->

                  <!-- Testimonial slide start -->
                  <div class="swiper-slide">
                    <div class="testimonial-slide">
                      <figure>
                        <img src="./../assets/images/author-2.jpg" alt="" />
                      </figure>

                      <div class="testimonial-entry">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Testimonial slide end -->

                  <!-- Testimonial slide start -->
                  <div class="swiper-slide">
                    <div class="testimonial-slide">
                      <figure>
                        <img src="./../assets/images/author-3.jpg" alt="" />
                      </figure>

                      <div class="testimonial-entry">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Testimonial slide end -->

                  <!-- Testimonial slide start -->
                  <div class="swiper-slide">
                    <div class="testimonial-slide">
                      <figure>
                        <img src="./../assets/images/author-4.jpg" alt="" />
                      </figure>

                      <div class="testimonial-entry">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Testimonial slide end -->

                  <!-- Testimonial slide start -->
                  <div class="swiper-slide">
                    <div class="testimonial-slide">
                      <figure>
                        <img src="./../assets/images/author-1.jpg" alt="" />
                      </figure>

                      <div class="testimonial-entry">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Testimonial slide end -->

                  <!-- Testimonial slide start -->
                  <div class="swiper-slide">
                    <div class="testimonial-slide">
                      <figure>
                        <img src="./../assets/images/author-2.jpg" alt="" />
                      </figure>

                      <div class="testimonial-entry">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Testimonial slide end -->

                  <!-- Testimonial slide start -->
                  <div class="swiper-slide">
                    <div class="testimonial-slide">
                      <figure>
                        <img src="./../assets/images/author-3.jpg" alt="" />
                      </figure>

                      <div class="testimonial-entry">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Testimonial slide end -->

                  <!-- Testimonial slide start -->
                  <div class="swiper-slide">
                    <div class="testimonial-slide">
                      <figure>
                        <img src="./../assets/images/author-4.jpg" alt="" />
                      </figure>

                      <div class="testimonial-entry">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          type setting industry
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Testimonial slide end -->
                </div>
              </div>

              <!-- Testimonial Pagination start -->
              <div class="testimonial-pagination">
                <div class="testimonial-prev">
                  <i class="flaticon-left-arrow"></i>
                </div>
                <div class="testimonial-next">
                  <i class="flaticon-right-arrow"></i>
                </div>
              </div>
              <!-- Testimonial Pagination end -->
            </div>
            <!-- Testimonial slider ends -->
          </div>
        </div>
      </div>
    </section>
    <!-- Testimonial section ends -->

    <!-- FAQ Section starts -->
    <section class="faq-layout-1" id="explore">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h2>Explore More</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- FAQ Tab starts -->
            <div class="faq-tabs">
              <!-- FAQ Tab navigation start -->
              <ul class="nav nav-tabs responsive" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    ><i class="flaticon-power"></i> Features</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    ><i class="flaticon-signs"></i> FAQ</a
                  >
                </li>

                <!-- <li class="nav-item">
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    ><i class="flaticon-float"></i> Quality Support</a
                  >
                </li> -->
              </ul>
              <!-- FAQ Tab navigation end -->

              <!-- FAQ Tab Content start -->
              <div class="tab-content responsive" id="myTabContent">
                <!-- Single Tab content start -->
                <div
                  class="tab-pane fade show active"  data-aos="fade-up"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div id="accordion1" class="faq-accordion">
                    <div class="card active">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <a
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            >Intuitive Interface</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion1"
                      >
                        <div class="card-body">
                          <p>
                            Our user-friendly interface makes navigating patient
                            records and managing appointments a breeze. Spend
                            less time on administrative tasks and more time
                            providing exceptional care to your patients.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            >Customizable Templates</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion1"
                      >
                        <div class="card-body">
                          <p>
                            Tailor our templates to fit the unique needs of your
                            practice. From intake forms to treatment plans,
                            personalize every aspect of your workflow for
                            maximum efficiency.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            >Secure Cloud Storage</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion1"
                      >
                        <div class="card-body">
                          <p>
                            Rest easy knowing that your patients' sensitive
                            information is safeguarded with state-of-the-art
                            security measures. Our cloud-based storage ensures
                            accessibility without compromising on privacy.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingFour">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                            >Seamless Integration</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordion1"
                      >
                        <div class="card-body">
                          <p>
                            Integrate StellarSync EMR Service seamlessly with
                            your existing systems and workflows. Say goodbye to
                            compatibility issues and hello to smooth,
                            uninterrupted operations.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingFive">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                            >24/7 Support</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#accordion1"
                      >
                        <div class="card-body">
                          <p>
                            Our dedicated support team is here for you around
                            the clock. Whether you have a question, encounter an
                            issue, or need assistance, we're just a phone call
                            or email away.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Single Tab content end -->

                <!-- Single Tab content start -->
                <div
                  class="tab-pane fade"  data-aos="fade-up"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div id="accordion2" class="faq-accordion">
                    <div class="card active">
                      <div class="card-header" id="headingSix">
                        <h5 class="mb-0">
                          <a
                            data-toggle="collapse"
                            data-target="#collapseSix"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            >How long will it take to set up?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseSix"
                        class="collapse show"
                        aria-labelledby="headingSix"
                        data-parent="#accordion2"
                      >
                        <div class="card-body">
                          <p>
                            Get started right away! Simply verify your email,
                            and you can begin using Stellasync immediately.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingSeven">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            >Will we be charged when our demo is up?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseSeven"
                        class="collapse"
                        aria-labelledby="headingSeven"
                        data-parent="#accordion2"
                      >
                        <div class="card-body">
                          <p>
                            We don't require your credit card information for a
                            demo account. If you decide to set up your hospital
                            facility after trying the demo, we'll request a
                            deposit payment.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingEight">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                            >Is the pricing the same for all hospitals,
                            regardless of practice size?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseEight"
                        class="collapse"
                        aria-labelledby="headingEight"
                        data-parent="#accordion2"
                      >
                        <div class="card-body">
                          <p>
                            The prices displayed here are not applicable to all
                            hospital sizes. The required deployment and pricing
                            vary depending on the size of the facility. Contact
                            us to determine if the price aligns with your
                            hospital's specifications based on the information
                            you provide.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingNine">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                            >Do I need to install anything?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseNine"
                        class="collapse"
                        aria-labelledby="headingNine"
                        data-parent="#accordion2"
                      >
                        <div class="card-body">
                          <p>
                            No, it's not necessary! All you need is an internet
                            connection. You can eliminate the need for
                            installation and local hosting.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingTen">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                            >Do we pay extra for more patients?
                          </a>
                        </h5>
                      </div>

                      <div
                        id="collapseTen"
                        class="collapse"
                        aria-labelledby="headingTen"
                        data-parent="#accordion2"
                      >
                        <div class="card-body">
                          <p>
                            Unlike most hospital management systems, we don't
                            charge based on the total number of patients you
                            add. Whether it's 8 patients or 800, the price
                            remains the same, with the exception of the size of
                            your hospital facility. This ensures significant
                            savings as your facility expands.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Single Tab content end -->

                <!-- Single Tab content start -->
                <div
                  class="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div id="accordion3" class="faq-accordion">
                    <div class="card active">
                      <div class="card-header" id="headingSix1">
                        <h5 class="mb-0">
                          <a
                            data-toggle="collapse"
                            data-target="#collapseSix1"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            >Which devices does it supported?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseSix1"
                        class="collapse show"
                        aria-labelledby="headingSix1"
                        data-parent="#accordion3"
                      >
                        <div class="card-body">
                          <p>
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable. If
                            you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing
                            hidden in the middle of text.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingSeven1">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseSeven1"
                            aria-expanded="false"
                            aria-controls="collapseSeven1"
                            >How do i know if i have the latest versions of the
                            app?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseSeven1"
                        class="collapse"
                        aria-labelledby="headingSeven1"
                        data-parent="#accordion3"
                      >
                        <div class="card-body">
                          <p>
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable. If
                            you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing
                            hidden in the middle of text.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingEight1">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseEight1"
                            aria-expanded="false"
                            aria-controls="collapseEight1"
                            >What if payment failed while purchasing any chipas
                            on app?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseEight1"
                        class="collapse"
                        aria-labelledby="headingEight1"
                        data-parent="#accordion3"
                      >
                        <div class="card-body">
                          <p>
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable. If
                            you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing
                            hidden in the middle of text.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingNine1">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseNine1"
                            aria-expanded="false"
                            aria-controls="collapseNine1"
                            >How's your reward point system work?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseNine1"
                        class="collapse"
                        aria-labelledby="headingNine1"
                        data-parent="#accordion3"
                      >
                        <div class="card-body">
                          <p>
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable. If
                            you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing
                            hidden in the middle of text.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingTen1">
                        <h5 class="mb-0">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapseTen1"
                            aria-expanded="false"
                            aria-controls="collapseTen1"
                            >Is the free version is avilable for lifetime?</a
                          >
                        </h5>
                      </div>

                      <div
                        id="collapseTen1"
                        class="collapse"
                        aria-labelledby="headingTen1"
                        data-parent="#accordion3"
                      >
                        <div class="card-body">
                          <p>
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable. If
                            you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing
                            hidden in the middle of text.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Single Tab content end -->
              </div>
              <!-- FAQ Tab Content end -->
            </div>
            <!-- FAQ Tab ends -->
          </div>
        </div>
      </div>
    </section>
    <!-- FAQ Section ends -->

    <!-- Version History Secton starts -->
    <section class="version-history-layout-1" hidden>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h2>Version Hisotry</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Version Hisotry slider start -->
            <div class="version-history-slider wow fadeInUp">
              <div class="swiper-container history-version-slider">
                <div class="swiper-wrapper">
                  <!-- Version slide start -->
                  <div class="swiper-slide">
                    <div
                      class="version-slide"
                      data-version="Launch"
                      data-date="June, 2001"
                    >
                      <div class="version-image">
                        <img
                          src="./../assets/images/version-image-1.png"
                          alt=""
                        />
                      </div>

                      <div class="version-entry">
                        <h3>Group chat & Profile setting</h3>
                        <p>
                          Before you arrive, check in from the train, plane, or
                          road. As you depart, leave your key and hit the road.
                          Before you arrive, check in from the train, plane, or
                          road. As you depart.
                        </p>

                        <ul>
                          <li>Easy to Use</li>
                          <li>Easy to Use</li>
                          <li>Account Activity</li>
                          <li>Account Activity</li>
                          <li>Friendly Support</li>
                          <li>Friendly Support</li>
                          <li>Great Security</li>
                          <li>Great Security</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Version slide end -->

                  <!-- Version slide start -->
                  <div class="swiper-slide">
                    <div
                      class="version-slide"
                      data-version="Version 1.1"
                      data-date="June, 2002"
                    >
                      <div class="version-image">
                        <img
                          src="./../assets/images/version-image-1.png"
                          alt=""
                        />
                      </div>

                      <div class="version-entry">
                        <h3>Group chat & Profile setting</h3>
                        <p>
                          Before you arrive, check in from the train, plane, or
                          road. As you depart, leave your key and hit the road.
                          Before you arrive, check in from the train, plane, or
                          road. As you depart.
                        </p>

                        <ul>
                          <li>Easy to Use</li>
                          <li>Easy to Use</li>
                          <li>Account Activity</li>
                          <li>Account Activity</li>
                          <li>Friendly Support</li>
                          <li>Friendly Support</li>
                          <li>Great Security</li>
                          <li>Great Security</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Version slide end -->

                  <!-- Version slide start -->
                  <div class="swiper-slide">
                    <div
                      class="version-slide"
                      data-version="Version 1.2"
                      data-date="June, 2003"
                    >
                      <div class="version-image">
                        <img
                          src="./../assets/images/version-image-1.png"
                          alt=""
                        />
                      </div>

                      <div class="version-entry">
                        <h3>Group chat & Profile setting</h3>
                        <p>
                          Before you arrive, check in from the train, plane, or
                          road. As you depart, leave your key and hit the road.
                          Before you arrive, check in from the train, plane, or
                          road. As you depart.
                        </p>

                        <ul>
                          <li>Easy to Use</li>
                          <li>Easy to Use</li>
                          <li>Account Activity</li>
                          <li>Account Activity</li>
                          <li>Friendly Support</li>
                          <li>Friendly Support</li>
                          <li>Great Security</li>
                          <li>Great Security</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Version slide end -->

                  <!-- Version slide start -->
                  <div class="swiper-slide">
                    <div
                      class="version-slide"
                      data-version="Version 1.3"
                      data-date="June, 2004"
                    >
                      <div class="version-image">
                        <img
                          src="./../assets/images/version-image-1.png"
                          alt=""
                        />
                      </div>

                      <div class="version-entry">
                        <h3>Group chat & Profile setting</h3>
                        <p>
                          Before you arrive, check in from the train, plane, or
                          road. As you depart, leave your key and hit the road.
                          Before you arrive, check in from the train, plane, or
                          road. As you depart.
                        </p>

                        <ul>
                          <li>Easy to Use</li>
                          <li>Easy to Use</li>
                          <li>Account Activity</li>
                          <li>Account Activity</li>
                          <li>Friendly Support</li>
                          <li>Friendly Support</li>
                          <li>Great Security</li>
                          <li>Great Security</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Version slide end -->

                  <!-- Version slide start -->
                  <div class="swiper-slide">
                    <div
                      class="version-slide"
                      data-version="Version 1.4"
                      data-date="June, 2005"
                    >
                      <div class="version-image">
                        <img
                          src="./../assets/images/version-image-1.png"
                          alt=""
                        />
                      </div>

                      <div class="version-entry">
                        <h3>Group chat & Profile setting</h3>
                        <p>
                          Before you arrive, check in from the train, plane, or
                          road. As you depart, leave your key and hit the road.
                          Before you arrive, check in from the train, plane, or
                          road. As you depart.
                        </p>

                        <ul>
                          <li>Easy to Use</li>
                          <li>Easy to Use</li>
                          <li>Account Activity</li>
                          <li>Account Activity</li>
                          <li>Friendly Support</li>
                          <li>Friendly Support</li>
                          <li>Great Security</li>
                          <li>Great Security</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Version slide end -->

                  <!-- Version slide start -->
                  <div class="swiper-slide">
                    <div
                      class="version-slide"
                      data-version="Version 1.5"
                      data-date="June, 2006"
                    >
                      <div class="version-image">
                        <img
                          src="./../assets/images/version-image-1.png"
                          alt=""
                        />
                      </div>

                      <div class="version-entry">
                        <h3>Group chat & Profile setting</h3>
                        <p>
                          Before you arrive, check in from the train, plane, or
                          road. As you depart, leave your key and hit the road.
                          Before you arrive, check in from the train, plane, or
                          road. As you depart.
                        </p>

                        <ul>
                          <li>Easy to Use</li>
                          <li>Easy to Use</li>
                          <li>Account Activity</li>
                          <li>Account Activity</li>
                          <li>Friendly Support</li>
                          <li>Friendly Support</li>
                          <li>Great Security</li>
                          <li>Great Security</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Version slide end -->

                  <!-- Version slide start -->
                  <div class="swiper-slide">
                    <div
                      class="version-slide"
                      data-version="Version 1.6"
                      data-date="June, 2001"
                    >
                      <div class="version-image">
                        <img
                          src="./../assets/images/version-image-1.png"
                          alt=""
                        />
                      </div>

                      <div class="version-entry">
                        <h3>Group chat & Profile setting</h3>
                        <p>
                          Before you arrive, check in from the train, plane, or
                          road. As you depart, leave your key and hit the road.
                          Before you arrive, check in from the train, plane, or
                          road. As you depart.
                        </p>

                        <ul>
                          <li>Easy to Use</li>
                          <li>Easy to Use</li>
                          <li>Account Activity</li>
                          <li>Account Activity</li>
                          <li>Friendly Support</li>
                          <li>Friendly Support</li>
                          <li>Great Security</li>
                          <li>Great Security</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Version slide end -->
                </div>
              </div>

              <div class="version-history"></div>

              <!-- Timeline slider pagination start -->
              <div class="version-navigation">
                <div class="version-prev">
                  <i class="flaticon-left-arrow"></i>
                </div>
                <div class="version-next">
                  <i class="flaticon-right-arrow"></i>
                </div>
              </div>
              <!-- Timeline slider pagination end -->
            </div>
            <!-- Version Hisotry slider end -->
          </div>
        </div>
      </div>
    </section>
    <!-- Version History Secton ends -->

    <!-- Screenshot section starts -->
    <section class="screenshot-layout-1" id="design" hidden>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h2>Design Screenshot</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Screenshot slider starts -->
            <div class="screenshot-slider-wrapper">
              <div class="swiper-container screenshot-slider">
                <div class="swiper-wrapper">
                  <!-- Screenshot slide start -->
                  <div class="swiper-slide">
                    <div class="screenshot-slide">
                      <img src="./../assets/images/screenshot-1.jpg" alt="" />
                    </div>
                  </div>
                  <!-- Screenshot slide end -->

                  <!-- Screenshot slide start -->
                  <div class="swiper-slide">
                    <div class="screenshot-slide">
                      <img src="./../assets/images/screenshot-2.jpg" alt="" />
                    </div>
                  </div>
                  <!-- Screenshot slide end -->

                  <!-- Screenshot slide start -->
                  <div class="swiper-slide">
                    <div class="screenshot-slide">
                      <img src="./../assets/images/screenshot-3.jpg" alt="" />
                    </div>
                  </div>
                  <!-- Screenshot slide end -->

                  <!-- Screenshot slide start -->
                  <div class="swiper-slide">
                    <div class="screenshot-slide">
                      <img src="./../assets/images/screenshot-4.jpg" alt="" />
                    </div>
                  </div>
                  <!-- Screenshot slide end -->

                  <!-- Screenshot slide start -->
                  <div class="swiper-slide">
                    <div class="screenshot-slide">
                      <img src="./../assets/images/screenshot-1.jpg" alt="" />
                    </div>
                  </div>
                  <!-- Screenshot slide end -->

                  <!-- Screenshot slide start -->
                  <div class="swiper-slide">
                    <div class="screenshot-slide">
                      <img src="./../assets/images/screenshot-2.jpg" alt="" />
                    </div>
                  </div>
                  <!-- Screenshot slide end -->
                </div>
              </div>

              <!-- Screenshot Pagination start -->
              <div class="screenshot-pagination">
                <div class="screenshot-prev">
                  <i class="flaticon-left-arrow"></i>
                </div>
                <div class="screenshot-next">
                  <i class="flaticon-right-arrow"></i>
                </div>
              </div>
              <!-- Screenshot Pagination end -->
            </div>
            <!-- Screenshot slider ends -->
          </div>
        </div>
      </div>
    </section>
    <!-- Screenshot section ends -->

    <!-- Download app section starts -->
    <section class="download-app-layout-1" id="download">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="download-entry"  data-aos="fade-up">
              <h2>
                Everything gets easier with StellaSync, for your patients.
              </h2>
              <p>
                Discover how StellaSync simplifies every aspect of patient care.
                From streamlined appointments to effortless record-keeping,
                StellaSync empowers your patients with a seamless healthcare
                experience.
              </p>

              <div class="ccenter"  data-aos="fade-up">
                <div class="app-download">
                  <a href="#" class="btn-download btn-apple"
                    ><i class="fa fa-apple"></i> <span>App Store</span></a
                  >
                  <a href="#" class="btn-download btn-android"
                    ><i class="fa fa-android"></i> <span>Google Play</span></a
                  >
                  <a href="#" class="btn-download btn-android"
                    ><img
                      src="./../assets/images/app_gallery.png"
                      class="app_gallery"
                    />
                    <span>App Gallery</span></a
                  >

                  <!-- <a href="#" class="btn-download btn-android"
                  ><i class="fa fa-app-gallery"></i> <span>Get in on</span>App Gallery
                  </a
                > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Download app section ends -->
  </div>
  <!-- <FooterPlugin /> -->
</template>

<script>
// import HeaderPlugin from "./Plugin/Header";
// import FooterPlugin from "./Plugin/Footer";
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'animate.css'
export default {
  name: "IndexPage",
  components: {
    // HeaderPlugin,
    // FooterPlugin,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {
    AOS.init()
  },
};
</script>

<style scoped>
body {
  overflow-x: hidden;
}

.howit_vid {
  height: 400px;
  width: 400px;
  margin-top: -80px;
}
.fimg {
  height: 250px;
  width: 400px;
}
.card_price {
  font-size: 18px;
}

.download-app-layout-1 .btn-download {
  position: relative;
  display: inline-block;
  background: linear-gradient(to right, #0e706a, #14a79e);
  background: -o-linear-gradient(to right, #0e706a, #14a79e);
  color: #fff;
  padding: 20px 20px 10px 0px;
  padding-top: 14px;
  padding-left: 40px;
  font-size: 20px;
  font-weight: bolder;
  margin-right: 10px;
  text-align: left;
  border-radius: 40px;
  width: 30%;
  height: 40px;
}

.download-app-layout-1 .btn-download i {
  font-size: 17px;
  position: absolute;
  top: 10px;
  left: 16px;
}

.app_gallery {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 10px;
  left: 16px;
}

.apppreimg {
  height: 450px;
  margin-left: 50%;
}

@media (min-width: 300px) and (max-width: 800px) {
  .howit_vid {
    height: 200px;
    width: 80%;
    margin-left: 10%;
    margin-top: -20px;
    margin-bottom: -30px;
  }
  .apppreimg {
    margin-left: 20%;
  }
  #home-tab,
  #profile-tab {
    font-size: 12px;
  }

  .download-app-layout-1 .btn-download {
    padding: 20px 0px 10px 10px;
    padding-top: 14px;
    padding-left: 30px;
    font-size: 14px;
    font-weight: bolder;
    margin-right: 10px;
    border-radius: 40px;
    width: 40%;
    height: 40px;
  }
  .download-app-layout-1 .btn-download i {
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 11px;
  }

  .app_gallery {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 11px;
  }
}
.mt {
  margin-top: -40px;
}
.mt2 {
  margin-top: -70px;
}
.mt3 {
  margin-top: -20px;
  margin-bottom: -20px;
}
.ccenter {
  text-align: center;
}

@media (min-width: 150px) and (max-width: 350px) {
  .apppreimg {
    margin-left: 13%;
  }
  #home-tab,
  #profile-tab {
    font-size: 12px;
    padding: 10px;
  }

}
</style>

<template>
  <div class="ccontainer">
    <!-- Page Header Section Starts -->
    <section class="page-header">
      <div class="container">
        <div class="row" hidden>
          <div class="col-lg-12">
            <!-- Page Header start -->
            <div class="page-header-title wow fadeInUp">
              <h1>Privacy Policy</h1>
              <p>Last updated : 20th May, 2024</p>
            </div>
            <!-- Page Header start -->
          </div>
        </div>
      </div>
    </section>
    <!-- Banner Section Ends <div class="row" style="text-align: center;margin-top: 100px;margin-bottom: -80px">-->

    <!-- Privacy Policy Page starts -->
	
    <div class="page-privacy-policy">
		
      <div class="container">
		<div class="page-header-title text-center" data-aos="fade-up">
		 <h1>Privacy Policy</h1>
              <p>Last updated : 20th May, 2024</p>
			  </div>
        <div class="row">
          <div class="col-md-3">
            <div class="privacy-sidebar">
              <div class="privacy-list">
                <ul>
                  <li class="privacy_links" @click="show_policy(1)">1. Introduction</li>
                  <li class="privacy_links" @click="show_policy(2)">2. Information StellaSync Collect </li>
                  <li class="privacy_links" @click="show_policy(3)">
                    3. How StellaSync Use Collected Information
                  </li>
                  <li class="privacy_links" @click="show_policy(4)">
                    4. How StellaSync Share Your Information
                  </li>
                  <li class="privacy_links" @click="show_policy(5)">
                    
                      5. How StellaSync Allow Users to Share Information
                  </li>
                  <li class="privacy_links" @click="show_policy(6)">6. Security of Your Information</li>
                  <li class="privacy_links" @click="show_policy(7)">7. Your Privacy Rights</li>
                  <li class="privacy_links" @click="show_policy(8)">8. Data Retention</li>
                  <li class="privacy_links" @click="show_policy(9)">
                    9. Accessing and Updating Your Information
                  </li>
                  <li class="privacy_links" @click="show_policy(10)">10. Third-Party Services</li>
                  <li class="privacy_links" @click="show_policy(11)">11. Minors</li>
                  <li class="privacy_links" @click="show_policy(12)">12. Transfer of Information Overseas</li>
                  <li class="privacy_links" @click="show_policy(13)">13. Changes to This Privacy Policy</li>
                  <li class="privacy_links" @click="show_policy(14)">14. Questions or Concerns</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <div class="privacy-content">
              <div class="privacy_conts cont1">
              <h3>1. Introduction</h3>
              <p>
                Welcome to StellarSync. Your privacy is of utmost importance to
                us at StellaSync. We are committed to protecting the privacy and
                security of our users' personal and medical information. Edy
                Cloud Limited owns and maintains StellaSync. This privacy policy
                (“Policy”) applies to all users of StellaSync and describes how
                StellaSync and our parent companies, subsidiaries, and
                affiliated companies (“StellaSync,” “we,” “us,” and/or “our”)
                may collect, use, disclose, and safeguard your information about
                you (“you” and “your” refers to the user of the Web site,
                “website”) that we obtain through <b>www.stellasync.com</b> and
                mobile applications that link to this Policy (collectively, the
                “Sites”) when you use our electronic medical records (EMR) and
                hospital management system (HMS) services.<br /><br />
                Your healthcare provider may also have their own privacy
                practices and/or policies that govern its collection and use of
                your data. Please be aware that we cannot oversee or take
                responsibility for your healthcare provider's privacy practices
                or how other entities handle your information. We strongly
                advise you to carefully review their privacy policies.<br /><br />
                By using StellarSync, you acknowledge that you have read,
                understood, and agreed to accept the practices, guidelines,
                and/or policies set forth in this Privacy Policy, whether you
                are using the Services, creating an account with us, visiting
                our Platform, or otherwise gaining access to the Services. You
                also give us your consent to collect, use, disclose, and/or
                process your personal information as set forth in this Privacy
                Policy. Please avoid using our services or accessing our
                Platform if you do not consent to the processing of your
                personal information, as this privacy policy outlines. Thank you
                for entrusting us with your personal information.
              </p>
</div>
              <div class="privacy_conts cont2">

              <h3>2. Information StellaSync Collect</h3>
              <p>
                We may collect the following kinds of information when you use
                the StellaSync Services:

                <span class="min_head">Information You Provide to Us</span>
				We may collect your personal information (such as name, email address, phone number, address, 
				date of birth, medical history and records etc.) when you provide it to us across our Services. This includes when you:
<ul class="writeup_list">
    <li>When you Sign up for an email newsletter, use our Services to send someone a message, register for our Services, fill out a form, use our online services (via our websites or applications) etc.</li>
    <li>Add a file to our services, such as a document, a picture, or another data file.</li>
    <li>Contact us by email, telephone or other means</li>
    <li>When you attain one of our training workshops or webinars for individuals or groups.</li>
    <li>Users enter other health-related data into our system.</li>
</ul>
Additionally, we collect information on your behalf when you grant us permission to access or import data from another user, a third party, or another service. other information you enter on or submit to our Services are just a few examples of the information we may collect about you.


<span class="min_head">Information We Collect Automatically</span>
				Automatic data collection occurs whenever you visit, use, or engage with our Services in any way, this includes receiving emails through our Services. We collect this information to improve and operate our Services in a variety of ways, including:

<ul class="writeup_list">
    <li>Preserving user preferences and data.</li>
    <li>Ensuring that session settings and activity remain intact.</li>
    <li>Users' authentication.</li>
    <li>Enable the security and support features.</li>
    <li>Adjust how informational messages, media, advertisements, and other content are delivered.</li>
    <li>Examine how our Services, including all of its features and content, are used and how well they work.
</li>
</ul>

<span class="min_head">Device Information:</span>
When you interact with our Services, we may collect information about the device you use to access our site. This information includes your IP address, device ID or unique identifier, device type, computer, operating system, and connection information that your device sends to us.

<span class="min_head">Cookies and Related Technologies:</span>
StellaSync and our partners—namely, affiliates, analytics, or service providers—use cookies, web beacons, pixels, and related technologies to provide functionality and identify you across different services and devices. This information includes statistics on page views, traffic to and from the sites, referral URLs, ad data, IP addresses, standard web log information, and anonymous information collected through cookies and web beacons.
At the individual browser level, users can control the use of cookies. To opt out of cookies, check your web browser to change your cookie settings or email support@stellasync.com. If you remove or decide not to accept cookies from us, you may still be able to use the website, but your access to some features or areas may be limited.


<span class="min_head">2.5 Payment and Billing Information:</span>
When you use StellarSync services that require payment, we may collect certain billing and payment information, such as the account information for your credit or debit card or other information. In order to submit your credit card information, you understand and agree to the sharing of your information with third-party payment processors and other third-party services (including, but not limited to, vendors who provide fraud detection services to us and other third parties).
<br>
We exclusively use your payment and billing information to process payments and manage billing-related activities. Specifically, we use this information to:

<ul class="writeup_list">
    <li>Process transactions and payments for services rendered.</li>
    <li>Send you billing statements and payment receipts/invoices.</li>
    <li>Manage your account and payment preferences.</li>
    <li>Verify your identity and prevent fraudulent transactions.</li>
    <li>Provide customer support for payment and billing inquiries.</li>
</ul>
These third parties may save your payment card information for future use with our services. We do not retain your payment card information, nor do we hold any control or responsibility over it. Our use of third-party services is subject to their contractual obligations to protect the privacy and security of your payment card information.
<span class="min_head">Information We Collect from Other Sources:</span>
For any reason outlined in this policy, we may receive, search for, or proactively collect information about you from other sources and combine it with information we already have about you. From time to time, we may obtain business contact information you make publicly available, including from third parties, for our marketing purposes.


              </p>
</div>
              <div class="privacy_conts cont3">
              <h3>3.  How StellaSync Use Collected Information</h3>
              <p>
				Subject to your consent, we may use your personal information for the following business purposes, where required by law:

                <span class="min_head">To Provide and Improve Our Services</span>

<ul class="writeup_list">
    <li>Manage patient records and healthcare information.</li>
    <li>Facilitate appointment scheduling and reminders.</li>
    <li>Process billing and payments.</li>
    <li>Enhance the functionality of our platform, incorporate new features, and enhance the overall user experience.</li>
    <li>Provide customer support and services.</li>
    <li>Authenticate user accounts</li>
</ul>

   <span class="min_head">To Communicate with You</span>

<ul class="writeup_list">
    <li>Send you updates, security alerts, and support messages.</li>
    <li>Respond to your questions and requests, or ask for feedback on our services.</li>
    <li>Facilitate communication between healthcare providers.</li>
</ul>

<span class="min_head">For Compliance and Protection</span>

<ul class="writeup_list">
    <li>Comply with legal and regulatory obligations</li>
    <li>Protect against fraudulent, unauthorized, or illegal activities.</li>
    <li>Unless a verified adult guarantor registers services, we do not collect information from children or minors</li>
</ul>
</p>
</div>
            
              <div class="privacy_conts cont4">

              <h3>4. How StellaSync Share Your Information</h3>
              <p>
				We share personal information with:
<span class="min_head">4.1 With Your Consent: </span>
 We may share your information with third parties if you have given us your explicit consent to do so.
 <span class="min_head">4.2 With Healthcare Providers: </span>
 To ensure proper medical treatment and support, we may share your information with healthcare professionals, government agencies, and relevant entities involved in your care.<br>
If an account administrator has administrative privileges over your account, they will have access to your account information.<br>
For example, if you choose to utilize our services in "one-on-one" interactions between a patient and a clinician or between a provider and another healthcare professional.
 <span class="min_head">4.3 With Service Providers:  </span>
We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, customer support marketing (including but not limited to advertising, attribution, deep-linking, direct-mail, mobile marketing, optimization, and retargeting), performance monitoring, and hosting. These providers are bound by contractual obligations to maintain your information's confidentiality and limit its use to the purposes we disclose to them.
 <span class="min_head">4.4 For Legal Reasons:  </span>
We may disclose your information if required to do so by law or in response to valid requests by legal and regulatory authorities (e.g., court orders, subpoenas).
We aim to detect, halt, probe, or address instances of fraud, illegal activity, or violations of our terms and conditions.
Our services may sell, merge, transfer, exchange, reorganize, or dispose of all or a portion of their business, including assets and shares. If such a transaction takes place, this policy, as later updated, will continue to apply to the acquiring company's use of your information.



              </p>
              </div>
             
              <div class="privacy_conts cont5">
              
              <h3>5. How StellaSync Allow Users to Share Information</h3>
              <p>
 <span class="min_head">5.1 One-on-One Communications:  </span>
Users can use our services to facilitate one-on-one conversations. For examples :
<ul class="writeup_list">
    <li>Sending a prescription to a pharmacist</li>
    <li>Sending another user a notification or appointment confirmation.</li>
    <li>Making a recommendation to another medical professional.</li>
    <li>Submitting a test request to a medical lab or radiology.</li>
    <li>Communicating with a patient.</li>
</ul>
In a one-on-one communication, users share information with one another or with a person or entity. This can involve disclosing contact details and other personally sensitive information, depending on the message.
 <span class="min_head">5.2 Records:</span>
You should be aware that this policy only applies to the data you provide when using our services. This policy does not apply if you communicate or exchange information with another user in person or through a channel other than our services. You should be selective in who you share your records and other information with because our services allow users to share information you share with them. Despite the fact that our services handle such transmissions, we are not liable for the behavior of those with whom you share your records and other information.
 <span class="min_head">5.3 Directories:</span>
Healthcare facilities have the option to have their contact and directory information listed in one or more of our professional directories of healthcare providers, which users and/or the general public can view. These directories offer profile details, including contact and specialization details, along with additional tools to assist users and the general public in locating and connecting with the listed individuals.
You might be required to provide personally identifiable information in addition to other information if you use our services to try to get in touch with or book an appointment with a provider listed in one of our directories.
 <span class="min_head">5.4 Training / Webinars </span>
As part of our services, we offer demo training and webinars where users can interact with other users or the broader public. Users should assume that any information they submit in one of our communities will be accessible to the general public. We strongly advise users to exercise caution when sharing any personally identifiable, health-related, or other sensitive information that could directly or indirectly link to any individual, including themselves.
</p>
</div>
              <div class="privacy_conts cont6">
<h3>6. Security of Your Information</h3>
<p>
The security of your personal information is important to us. To protect your personal data, use electronic storage and transmission with appropriate security technologies. When you enter sensitive information, such as a payment card number, on our services, we encrypt that information using secure socket layer technology (SSL). We implement appropriate technical and robust security measures to protect information, including encryption (in transit and at rest), access controls (user authentication, authorization, and access restrictions), Regular backups and disaster recovery procedures.We maintain secure servers and data centers, implement firewalls and intrusion detection systems, conduct regular security testing and vulnerability assessments, and implement organizational measures to safeguard your personal information against unauthorized access, use, alteration, and disclosure. Please be aware that while we follow generally accepted industry standards to protect the personally identifiable information submitted to us, both during transmission and once we receive it,
 no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security of any information stored on, sent via, or received from our services.
  </p> </div>          
       <div class="privacy_conts cont7">
<h3>7. Your Privacy Rights</h3>
              <p>
                Users have the right to:
 <span class="min_head">7.1 Access and Update: </span>
You have the right to access and update your personal information. 
 <span class="min_head">7.2 Data Portability: </span>
You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format.
 <span class="min_head">7.3 Deletion:  </span>
You have the right to request the deletion of your personal information, subject to certain exceptions (e.g., where we need to retain your information to comply with legal obligations).

              </p>
</div>
              <div class="privacy_conts cont8">

              <h3>8. Data Retention</h3>
              <p>
                We retain personal information and protected health information (PHI) for as long as is necessary to provide our services and comply with legal requirements. We delete information when it is no longer necessary or upon user request (subject to legal requirements).

              </p>
</div>
              <div class="privacy_conts cont9">

              <h3>9. Accessing and Updating Your Information</h3>
              <p>
               We design our services to provide you with access to the data you submit and the necessary tools to modify it in compliance with applicable law. You can do this by logging into our services and changing your information, or by getting in touch with a customer support agent. However, please be aware of the significant restrictions listed below. Before processing your request, we may, in some cases, ask you to prove your identity.
<br>
Please be aware that you cannot access, modify, or delete another user's account's content unless you have administrative privileges under the registered medical firm.<br>
You won't be able to view, edit, or delete information that you provided to another user or a third party through our services. You won't be able to read, modify, or delete information that another 
user of our services submits to identify you.<br>
Current rules or regulations may require some users, like healthcare providers, to maintain their information on file for an extended period or permanently. In addition, we might be required to keep such information forever under separate legal or regulatory requirements. Last but not least, we keep backups of the data stored by our services for infinite amounts of time for disaster recovery and business continuity purposes.
              </p>
</div>
              <div class="privacy_conts cont10">

              <h3>10. Third-Party Services</h3>
<p>This privacy policy does not apply to third-party services offered by websites, other online services, or advertisements that may be displayed on the Services or on websites to which our Services may establish connections. These third-party services have their own data collection policies. We are not responsible or liable for the privacy practices or content of these third-party sites. You should study any third-party privacy policies to understand their procedures because we have no control over how they acquire or use information.
</p>
</div>
 <div class="privacy_conts cont11">
 <h3>11. Minors: </h3>
<p>Minors under the age of eighteen should not use the sites. StellaSync does not wish to obtain any information from or about such minors through the sites. If you are under eighteen years old, do not use StellaSync services.
<br>
Without limiting the above, the StellaSync.com website does allow persons over the age of 18, such as you, parents, and guardians, to provide, share, and store personal information about others, including minors and children. Any user providing, storing, or submitting information on behalf of a child assumes full responsibility over the submission, use, and transmission of such information.
</p>
</div>

<div class="privacy_conts cont12">
 <h3>12. Transfer of Information Overseas</h3>
<p>For one or more of the following reasons, your personal data and/or information may be transferred to, stored, or processed outside of the country in which you reside. Most of the time, we will process your personal information outside of the country because that is where our servers are. Your information will only be transferred outside of Nigeria by StellaSync in accordance with privacy laws and/or other applicable laws. Your use of the sites or the provision of any information therefore constitutes your consent to the transfer to and from, processing, usage, sharing, and storage of information about you outside of the country in which you reside, as set out in this policy.</p>
</div>

   <div class="privacy_conts cont13">
 <h3>13. Changes to This Privacy Policy</h3>
<p>We may update this privacy policy from time to time. If we change how we use your personally identifiable information, we will notify you of any changes by email or by posting the new privacy policy on our platform and updating the effective date. Your continued use of our services after such changes constitutes your acceptance of the new privacy policy. We advise you to review it from time to time to see if there have been any modifications, additions, or updates. You should stop using our services if you disagree with any of the amendments, terms, or conditions contained in this policy.</p>
</div>

<div class="privacy_conts cont14">

 <h3>14. Questions or Concerns</h3>
<p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at support@stellasync.com or call +234 (0) 810 296 5619.</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Privacy Policy Page ends -->

    <!-- Download app section starts -->
    <section class="download-app-layout-1" hidden>
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="download-entry">
              <h2>Everthing on the go in your pocket</h2>
              <p>
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true
                generator on the Internet.
              </p>

              <div class="app-download">
                <a href="#" class="btn-download btn-apple"
                  ><i class="fa fa-apple"></i> <span>Download on the</span>App
                  Store</a
                >
                <a href="#" class="btn-download btn-android"
                  ><i class="fa fa-android"></i> <span>Get in on</span>Google
                  Play</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Download app section ends -->
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";
import $ from 'jquery'
// import jquery from 'jquery';
export default {
  name: "ContactPage",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    show_policy(num) {
     
 const elements = document.querySelectorAll(`.privacy_conts`);
        elements.forEach(element => {
        element.style.display = 'none';
    });
    $(`.cont${num}`).show(500)
    }
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style scoped>
/* .howit_vid {
  height: 400px;
  width: 400px;
  margin-top: -80px;
} */
.fimg {
  height: 250px;
  width: 400px;
}
.card_price {
  font-size: 18px;
}
.contact-single {
  height: 180px;
}
.page-header {
  background-image: url(../assets/images/stella.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;
  background-color: #14a79e;
  height: 440px;
}

#select {
  padding: 0px;
  padding-left: 10px;
}
.min_head {
  display: block;
  font-weight: bolder;
  margin-top: 10px;
  margin-bottom: 10px;
}

.writeup_list{margin-top: 10px;
  margin-bottom: 10px;}
  .privacy_links:hover{color:#14a79e;cursor: pointer;}
.privacy_conts{display: none;}
.cont1{display:block;}

@media (min-width: 300px) and (max-width: 800px) {
  .page-header {
    height: 100px;
  }
  .contact-single {
    height: 140px;
    padding: 10px;
  }
  .mt {
    margin-top: -50px;
  }
}
</style>

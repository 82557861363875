<template>
  <div class="ccontainer" style="overflow-x: hidden;">
    <!-- Page Header Section Starts -->
	<section class="page-header" >
		<div class="container">
			<div class="row" hidden>
				<div class="col-lg-12">
					<!-- Page Header start -->
					<div class="page-header-title wow fadeInUp">
						<h1 >Contact us</h1>
						<p>giving information on its origins</p>
					</div>
					<!-- Page Header start -->
				</div>
			</div>
		</div>	
	</section>
	<!-- Banner Section Ends -->
	
	<!-- Contact us Page starts -->
  <div class="row" style="text-align: center;margin-top: 100px;margin-bottom: -80px">
				<div class="col-lg-12 mt">
					<!-- Page Header start -->
					<div class="page-header-title" data-aos="fade-up">
						<h1>Contact us</h1>
						<!-- <p>giving information on its origins</p> -->
					</div>
					<!-- Page Header start -->
				</div>
			</div>
	<div class="page-contact">
		<div class="container">
			<div class="row">
				<div class="col-md-4" data-aos="fade-up" data-aos-delay="100">
					<div class="contact-single">
						<div class="icon-box">
							<i class="fa fa-envelope-o"></i>
						</div>
						
						<p>support@stellasync.com <br></p>
					</div>
				</div>
				
				<div class="col-md-4" data-aos="fade-up" data-aos-delay="200">
					<div class="contact-single">
						<div class="icon-box">
							<i class="fa fa-phone"></i>
						</div>
						
						<p>+234 (0) 810 296 5619 
              <br>
            </p>
					</div>
				</div>
				
				<div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
					<div class="contact-single">
						<div class="icon-box">
							<i class="fa fa-map-marker"></i>
						</div>
						
						<p>Plot #7b Okigwe Road. Beside Stanbic IBTC Bank. After Mbari Street Junction.  <br />Owerri. Imo State. Nigeria.</p>
					</div>
				</div>
			</div>
			
			<div class="row mt-6 pt-5">
				<div class="col-md-8 offset-md-2">
					<div class="section-title" data-aos="fade-up">
						<h2>Get in Touch</h2>
					</div>
					
					<!-- Contact Form start -->
					<div class="contact-form" data-aos="zoom-in" >
						<form id="contactForm" action="#" method="post" data-toggle="validator">
							<div class="row">
								<div class="form-group col-md-6 col-sm-6">
									<input type="text" name="name" id="name" class="form-control" placeholder="Your Name" required>
									<div class="help-block with-errors"></div>
								</div>
								
								<div class="form-group col-md-6 col-sm-6">
									<input type="email" name="email" id="email" class="form-control" placeholder="Business Email Address" required>
									<div class="help-block with-errors"></div>
								</div>
                <div class="form-group col-md-6 col-sm-6">
									<select class="organization form-control" id="select">
                    <option value=""  selected>Organization Type</option>
                    <option value="">Hospital</option>
                    <option value="">Clinic</option>
                    <option value="">Maternity</option>
                  </select>
									<div class="help-block with-errors"></div>
								</div>
                <div class="form-group col-md-6 col-sm-6">
									<input type="text" id="address" class="form-control" placeholder="Organisation Name" required>
									<div class="help-block with-errors"></div>
								</div>
                <div class="form-group col-md-6 col-sm-6">
									<select class="Country form-control" id="select">
                    <option value="" selected>Select Country</option>
                    <option value="">Nigeria</option>
                  
                  </select>
									<div class="help-block with-errors"></div>
								</div>
                <div class="form-group col-md-6 col-sm-6">
									<input type="text" id="address" class="form-control" placeholder="Address" required>
									<div class="help-block with-errors"></div>
								</div>

								<div class="form-group col-md-12 col-sm-12">
									<input type="text" name="subject" id="subject" class="form-control" placeholder="Social media handles: twitter,Instagram,Linkedin. Website" required>
									<div class="help-block with-errors"></div>
								</div>
								
								<div class="form-group col-md-12 col-sm-12">
									<textarea rows="8" name="message" id="message" class="form-control" placeholder="How can we help you?" required></textarea>
									<div class="help-block with-errors"></div>
								</div>
								
								<div class="col-md-12 col-sm-12 text-center">
									<button type="submit" class="btn-contact" title="Submit Your Message!">Submit</button>
									<div id="msgSubmit" class="h3 text-left hidden"></div>
								</div>
							</div>
						</form>
					</div>
					<!-- Contact Form end -->
				</div>
			</div>
		</div>
	</div>
	<!-- Contact us Page ends -->
	
	<!-- Download app section starts -->
	<section class="download-app-layout-1" hidden>
		<div class="container">
			<div class="row">
				<div class="col-lg-5">
					<div class="download-entry">
						<h2>Everthing on the go in your pocket</h2>
						<p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
						
						<div class="app-download">
							<a href="#" class="btn-download btn-apple"><i class="fa fa-apple"></i> <span>Download on the</span>App Store</a>
							<a href="#" class="btn-download btn-android"><i class="fa fa-android"></i> <span>Get in on</span>Google Play</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- Download app section ends -->
  </div>
  
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'animate.css'

export default {
  name: "ContactPage",
  components: {
 
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {
	AOS.init()
  },
};
</script>

<style scoped>
/* .howit_vid {
  height: 400px;
  width: 400px;
  margin-top: -80px;
} */
.fimg {
  height: 250px;
  width: 400px;
}
.card_price {
  font-size: 18px;
}
.contact-single{height:180px}
.page-header{background-image: url(../assets/images/stella.jpg);background-size: contain;background-repeat: no-repeat;
background-position-x: 50%;
background-position-y:100%;
background-color: #14a79e;
height: 440px;}

#select{padding:0px;padding-left: 10px;}

@media (min-width: 300px) and (max-width: 800px) {
	.page-header{height:100px}
.contact-single{height:140px;padding: 10px;}
.mt {
  margin-top: -50px;
}
}
</style>

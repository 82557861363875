import { createRouter, createWebHistory } from "vue-router";

import Index from "../components/Index";
import Contact from "../components/Contact.vue";
import Privacy_policy from "../components/Privacy_policy.vue";
import Terms from "../components/Terms_conditons.vue";
import Refund from "../components/Refund_cancellation.vue";
import Account_deletion from "../components/Account_deletion.vue";
;

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/privacy-policy",
    name: "privacy_policy",
    component: Privacy_policy,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/refund-and-cancellation",
    name: "Refund",
    component: Refund,
  },

  {
    path: "/account-deletion",
    name: "Account_deletion",
    component: Account_deletion,
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

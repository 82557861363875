import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import $ from "jquery";


// import popper.js
// import store from "./store";


// import Vue3Toastify from "vue3-toastify";
// import "vue3-toastify/dist/index.css";
// import VueSplide from '@splidejs/vue-splide';
// import '@splidejs/vue-splide/css/core';




// css files
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/swiper.min.css";
import "./assets/css/animate.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/slicknav.css";
import "./assets/css/custom.css";
import "./assets/css/pages.css";



// js files
import "./assets/js/jquery-1.12.4.min.js";
import "./assets/js/bootstrap.min.js";
// import "./assets/js/responsive-tabs.js";
// import "./assets/js/validator.min.js";
import "./assets/js/wow.js";
import "./assets/js/swiper.min.js";
import "./assets/js/jquery.magnific-popup.min.js";
// import "./assets/js/jquery.slicknav.js";
import "./assets/js/SmoothScroll.js";
// import "./assets/js/function.js";






// app.use(Vue3Toastify, {
//   theme: "auto",
//   position: "top-right",
//   pauseOnFocusLoss: false,
//   transition: "flip",
//   dangerouslyHTMLString: true,
// });
// app.use( VueSplide );


// Optionally, if you need to use the 'toast' object directly
// const { toast } = Vue3Toastify;
// app.config.globalProperties.$toast = toast;

// app.use(router).use(store).mount("#app");
const app = createApp(App);
app.use(router).mount("#app");

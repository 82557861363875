<template>
  <!-- Header Section Starts-->
  <header class="header-layout-1">
    <nav class="navbar navbar-expand-md navbar-light fixed-top" id="navigation">
      <div class="container">
        <router-link class="navbar-brand" to="/"
          ><img src="../../assets/images/logo.png" alt="" class="logot" />
          <span class="site_name">StellaSync<br /> </span
          ><sub class="tagline"
            >Unifying health records, transforming lifes.</sub
          ></router-link
        >
        <ul class="navbar-nav ml-auto" id="main-menu">
          <li class="nav-item">
            <span class="nav-link active" @click="link('/')" active='active'>Home</span>
          </li>
          <li class="nav-item">
            <span class="nav-link" @click="link('#howitwork')"
              >How it works</span
            >
          </li>
          <li class="nav-item">
            <span class="nav-link" @click="link('#pricing')"
              >Pricing</span
            >
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#testimonial">Testimonial</a>
          </li> -->
          <li class="nav-item">
            <span class="nav-link" @click="link('#explore')"
              >Explore</span
            >
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#design">Design</a>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" to="/Contact"
              >Contact</router-link
            >
          </li>
          <li class="nav-item">
            <span class="nav-link download" @click="link('#download')"
              >Download App</span
            >
          </li>

          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Pages</a>
            <ul>
              <li><a href="privacy-policy.html">Privacy Policy</a></li>
              <li><a href="404.html">404</a></li>
              <li><a href="knowledge-base.html">Knowledge Base</a></li>
              <li>
                <a href="knowledge-base-single.html">Knowledge Base Single</a>
              </li>
              <li><a href="blog.html">Blog Post</a></li>
              <li><a href="blog-single.html">Blog Single</a></li>
              <li><a href="contact.html">Contact Us</a></li> 
            </ul>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link download-link" to="/Contact"
              >Request Demo</router-link
            >
          </li>
        </ul>

        <div class="navbar-toggle"></div>
        <div id="responsive-menu"></div>
      </div>
    </nav>
  </header>
  <!-- Header Section ends -->
</template>

<script>
import $ from "jquery";
// window.$ = $;

export default {
  name: "HeadPlugin",
  data() {
    return {
    
    };
  },
  mounted() {

    $(".preloader").fadeOut(1000);
    document.addEventListener("DOMContentLoaded", function() {
      var links = document.querySelectorAll(".header-layout-1 .navbar-light .navbar-nav .nav-item .nav-link");

// Loop through each link
links.forEach(function(link) {
    // Add a click event listener to each link
    link.addEventListener("click", function(event) {
        // Prevent default link behavior
        event.preventDefault();

        // Remove the "active" class from all links
        links.forEach(function(link) {
            link.classList.remove("active");
          
        });

        // Add the "active" class to the clicked link
        this.classList.add("active");
    });
});
    });
  },
  methods: {
    link(link) {
      
      if (this.$route.name == "Contact" || this.$route.name == "privacy_policy" || this.$route.name == "Terms" || this.$route.name == "Refund" || this.$route.name == "Account_deletion") {
        if (link == "/") {
          window.location = link;
        } else {
          window.location = "/" + link;
        }
      } else {
        window.location = link;
      }

  
      
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.site_name {
  color: black;
  font-size: 36px;
  font-weight: bolder;
  letter-spacing: 1px;
  padding-top: 10px;
  margin-top: 10px;
}
.tagline {
  font-size: 9px;
  position: absolute;
  top: 70%;
  left: 9.5%;
  font-weight: bolder;
  color: #14a79e;
}

.header-layout-1 .navbar-light .navbar-nav .nav-item .active.nav-link{
  color: #14cbbf;
}
.nav-item{cursor: pointer;}

@media (min-width: 300px) and (max-width: 800px) {
  .tagline {

  left: 27%;
  }
}

@media (min-width: 150px) and (max-width: 350px) {
  .tagline {

left: 32%;
}
}

























/* .logot{height: 400px;} */

/* #6ff1b5 - original #14a79e - new */
</style>

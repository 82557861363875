<template>
  <div class="fixed_cont animate__animated animate__tada animate__duration-4s">
    <a
      href="https://api.whatsapp.com/send?phone=2348102965619"
      target="_blank"
      title="Contactn us on whatsapp"
      style="color: white"
      ><i class="fa fa-whatsapp f_icon" style="color: white"></i
    ></a>
  </div>
  <!-- Mega Footer section starts -->
  <section class="mega-footer-layout-1">
    <div class="container">
      <div class="row">
        <!-- Quick link start -->
        <div class="col-lg-3 col-md-6 ibx">
          <div class="footer-link" data-aos="fade-up" data-aos-delay="100">
            <h3>Company</h3>
            <ul>
              <li><a href="#">About us</a></li>
              <li><router-link to="Contact">Contact Us</router-link></li>
              <li><a href="#">Team</a></li>
              <li><a href="#">Our Services</a></li>
            </ul>
          </div>
        </div>
        <!-- Quick link end -->

        <!-- Quick link start -->
        <div class="col-lg-3 col-md-6 ibx">
          <div class="footer-link" data-aos="fade-up" data-aos-delay="200">
            <h3>Legal</h3>
            <ul>
              <li>
                <router-link to="/terms-and-conditions"
                  >Terms & Conditions</router-link
                >
              </li>
              <li>
                <router-link to="/refund-and-cancellation"
                  >Refund & Cancellation</router-link
                >
              </li>
              <li>
                <router-link to="/privacy-policy">Privacy policy</router-link>
              </li>
              <!-- <li><a href="#">Offer Terms</a></li> -->
              <li>
                <router-link to="account-deletion"
                  >Account Deletion</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- Quick link end -->

        <!-- Contact info start -->
        <div class="col-lg-3 col-md-6">
          <div class="footer-info" data-aos="fade-up" data-aos-delay="300">
            <h3>Contact us</h3>
            <p>
              Plot #7b Okigwe Road. Beside Stanbic IBTC Bank. After Mbari Street
              Junction. <br />Owerri. Imo State. Nigeria.
            </p>
            <p>
              <a href="mailto:hello@stellasync.com" style="color:#8d97ad">hello@stellasync.com</a>
            </p>
            <p>+234 (0) 810 296 5619</p>
          </div>
        </div>
        <!-- Contact info end -->

        <!-- Available Payment start -->
        <div class="col-lg-3 col-md-6">
          <div class="footer-payment" data-aos="fade-up" data-aos-delay="400">
            <h3>We Accept</h3>

            <div class="footer-payment-box">
              <i class="fa fa-cc-visa"></i>
              <i class="fa fa-cc-mastercard"></i>
            </div>
          </div>
        </div>
        <!-- Available Payment end -->
      </div>
    </div>
  </section>
  <!-- Mega Footer section ends -->
  <!-- Footer section starts -->
  <footer class="footer-layout-1 mt">
    <div class="container">
      <div class="row">
        <!-- Footer Copyright start -->
        <div class="col-md-6">
          <div class="footer-copyright">
            <p>
              &copy; {{ currentyear() }} StellaSync LTD. All Rights Reserved.
            </p>
          </div>
        </div>
        <!-- Footer Copyright end -->
        <!-- data-aos="fade-up" data-aos-delay="300" -->
        <!-- Footer Social start -->
        <div class="col-md-6 mt3">
          <div class="footer-social">
            <h3 class="ib">Follow us:</h3>
            <a href="https://facebook.com/stellasyncme" target="_blank"
              ><i class="fa fa-facebook"></i
            ></a>
            <a href="https://x.com/stellasyncme" target="_blank"
              ><i class="fa fa-twitter"></i
            ></a>
            <a href="https://linkedin.com/company/stellasyncme" target="_blank"
              ><i class="fa fa-linkedin"></i
            ></a>
          </div>
        </div>
        <!-- Footer Social end -->
      </div>
    </div>
  </footer>
  <!-- Footer section ends -->
</template>

<script>
export default {
  name: "IndexPage",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    currentyear() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      return currentYear;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.ib {
  display: inline-block;
}
.mt {
  margin-top: -50px;
  margin-bottom: -20px;
}
.mt3 {
  margin-top: -20px;
  margin-bottom: 0px;
}
.fixed_cont {
  width: 80px;
  height: 80px;
  background-color: #14a79e;
  border-radius: 12px;
  box-shadow: 2px 2px rgba(255, 255, 255, 0.295);
  padding: 7px 5px 5px 10px;
  position: fixed;
  left: 85%;
  bottom: 15%;
  z-index: 999;
}
.f_icon {
  font-size: 66px;
  color: white;
}
a,
a:visited {
  color: white;
}
@media (min-width: 300px) and (max-width: 800px) {
  .ibx {
    width: 45%;
  }

  .fixed_cont {
    width: 50px;
    height: 50px;
    background-color: #14a79e;
    border-radius: 12px;
    box-shadow: 2px 2px rgba(255, 255, 255, 0.295);
    padding: 7px 5px 5px 10px;
    position: fixed;
    left: 85%;
    bottom: 15%;
    z-index: 999;
  }
  .f_icon {
    font-size: 36px;
    color: white;
  }
}

@media (min-width: 150px) and (max-width: 350px) {
  .fixed_cont {
    left: 80%;
  }
}
</style>

<template>
  <div class="ccontainer">
    <!-- Page Header Section Starts -->
    <section class="page-header">
      <div class="container">
        <div class="row" hidden>
          <div class="col-lg-12">
            <!-- Page Header start -->
            <div class="page-header-title wow fadeInUp">
              <h1>Refund and Cancellation Policy</h1>
              <p>Last updated : 20th May, 2024</p>
            </div>
            <!-- Page Header start -->
          </div>
        </div>
      </div>
    </section>
    <!-- Banner Section Ends <div class="row" style="text-align: center;margin-top: 100px;margin-bottom: -80px">-->

    <!-- Privacy Policy Page starts -->
	
    <div class="page-privacy-policy">
		
      <div class="container">
		<div class="page-header-title text-center" data-aos="fade-up">
		 <h1>Refund and Cancellation Policy</h1>
              <p>Last updated : 20th May, 2024</p>
			  </div>
        <div class="row">
          <div class="col-md-3">
            <div class="privacy-sidebar">
              <div class="privacy-list">
                <ul>
                  <li class="privacy_links" @click="show_policy(1)">Introduction</li>
                  <li class="privacy_links" @click="show_policy(2)">Reschedule</li>
                  <li class="privacy_links" @click="show_policy(3)">Refund Requests                  </li>
                  <li class="privacy_links" @click="show_policy(4)">
                    Refund 
                  </li>
                  <li class="privacy_links" @click="show_policy(5)">Non-Refundable Services
                  </li>
                  <li class="privacy_links" @click="show_policy(6)">Changes to the Policy</li>
                  <li class="privacy_links" @click="show_policy(7)">Contact Us</li>
                  
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <div class="privacy-content">
              <div class="privacy_conts cont1">
              <h3>Introduction</h3>
              <p>
                At StellarSync, we strive to provide our users with the best possible service. We understand that situations may arise where you need to cancel your subscription or request a refund. This Refund & Cancellation Policy outlines the terms and conditions for such requests. By using StellarSync, you acknowledge that you have read, understood, and agreed to this Refund & cancellation policy.
<br>
Stellasync provides an online demo for evaluation purposes, allowing users to test the software before making a purchase to avoid the stress of cancellation and refund. At StellaSync, we are constantly working on innovative solutions to enhance and improve our electronic medical records system, ensuring the utmost satisfaction for our users. However, if you are dissatisfied or no longer wish to continue with our services, you have the option to cancel or deactivate your access at any time. We have a refund policy in place that allows for a full refund if you choose to cancel your subscription within the first or second month of use. Once the initial 2 months have passed, StellaSync does not credit or refund subscription payments in the event of deactivation or account cancellation.

              </p>
</div>
              <div class="privacy_conts cont2">

              <h3>Reschedule:</h3>
             
<ul class="writeup_list">
    <li>Only before the scheduled date and time, and at the same medical firm, can you reschedule an online paid consultation appointment, subject to the availability of doctors.</li>
    <li>You can only reschedule all online paid appointments once you generate the corresponding invoice. Once you generate the invoice, you should follow the cancellation and refund processes mentioned below, and re-process for a fresh booking if necessary.</li>
    <li>You can reschedule all non-paid consultation appointments.</li>
    <li>When you attain one of our training workshops or webinars for individuals or groups.</li>
    <li>Users enter other health-related data into our system.</li>
</ul>
Additionally, we collect information on your behalf when you grant us permission to access or import data from another user, a third party, or another service. other information you enter on or submit to our Services are just a few examples of the information we may collect about you.


<h3>Cancellation </h3>
<p>
<span class="min_head">Cancellation by the Customer:</span>
<ul class="writeup_list">
    <li>For any online paid appointments, where services were not availed at the appointed time despite invoices
       being generated against such online appointment booking, through a written request for cancellation, 100% fees will be refunded.</li>
</ul>

<span class="min_head">Cancellation By Owner and/or the Authorized Person/Staff Representing the Medical Firm (Stellasync User):</span>
<ul class="writeup_list">
    <li>The doctor occasionally cancels or postpones appointments. Should this occur, we will attempt to contact you and refund the amount as per our policy. You can schedule a new appointment at your convenience, subject to the doctor's availability.
</li>
</ul>
If the medical firm, with whom the user booked a paid appointment through the mobile app, is unable to meet the user, they must notify us at support@stellasync.com within five (5) days of the event. If this happens, the user will receive a refund of the entire consultation amount from the mobile app within the next five (5) to six (6) business days, using the original payment method they used during booking. If the user fails to show up for the scheduled appointment with the medical firm without cancelling beforehand, we will not refund the amount. 
<br>
Users will not be entitled for any refunds in cases where, the medical firm is unable to meet the User at the exact time of the scheduled appointment time and the User is required to wait, irrespective of the fact whether the User is required to wait or choose to not obtain the medical services from the said medical firm.

 </p>
</div>
              <div class="privacy_conts cont3">
              <h3>Refund Requests</h3>
              <p>
			Although we hate to see you go, you must submit your request for a refund or cancellation in written form, via mail. please contact our customer support team at support@stellasync.com with the following information:


<ul class="writeup_list">
    <li>Your account details (including the name of your medical firm, your email address, and your subscription plan.)</li>
    <li>A detailed description of the issue or reason for the refund or cancellation request.</li>
    <li>Any relevant documentation or evidence supporting your request (e.g., screenshots, error messages).</li>
   
</ul>
Our team will review your request and respond within 7 business days. We will process your refund within 14 business days if we approve your request. Once you cancel your subscription or access, StellaSync will no longer be available to you. However, you can still export your data during the notice period.

</p>
</div>
            
              <div class="privacy_conts cont4">

              <h3>Refund </h3>
              <p>
				
<span class="min_head">Cancellation by the Customer:</span>
 <ul class="writeup_list">
    <li>Once we receive a valid cancellation of the appointment as mentioned above, we will issue a refund in the same manner as the original payment. Please be aware that the cardholder's issuing bank manages any delays in crediting the cardholder's credit card or bank account.
</li>
    <li>subject to all terms and conditions being met successfully, we will process the refund amount within 15 business days from the cancellation date. </li>
    <li>The above-mentioned cancellation process and refund processing time may vary under special circumstances (such as holidays, strikes, bank delays etc) and the organization cannot be held liable for the delay.
</li>
     <li>No refunds/cancellation requests shall be entertained in case of payment against Services received.
</li>
    <li>No refund will be given for partially utilized services at any time.</li>
</ul>
<span class="min_head">Note:</span>
<ul class="writeup_list">
    <li>If you are unable to show up for your appointment then you should cancel the appointment by email or call our support team.
</li>
    <li>Cancellation of scheduled appointment by mail or phone up is allowed until the time of the appointment.</li>
    <li> In the event of a valid patient-no-Show (PNS) report, StellaSync will temporarily disable the user's account from booking further online appointments. Users understand that, actions such as this are included as a deterrent to stop Users from misusing the mobile app, and the loss of business hours incurred by the medical firm.
</li>
</ul>
</p></div>
             
              <div class="privacy_conts cont5">
              
              <h3>Non-Refundable Services</h3>
              <p>
 <span class="min_head">The following services are non-refundable: </span>
<ul class="writeup_list">
    <li>Customization fees</li>
    <li>Training/webinar fees</li>
    <li>Any other one-time service charges</li>
    
</ul>
</p>
</div>
              <div class="privacy_conts cont6">
<h3>Changes to the Policy</h3>
<p>
We reserve the right to modify this Refund & Cancellation Policy at any time. Any changes will be posted on our website and will take effect immediately upon posting. Your continued use of StellarSync after changes are posted constitutes your acceptance of such changes.
</p> </div>          
       <div class="privacy_conts cont7">
<h3>Contact Us</h3>
              <p>
               If you have any questions or concerns about this Refund & Cancellation Policy, please contact us at support@stellasync.com
<br>
Thank you for choosing StellarSync EMR/HMS.

              </p>
</div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Privacy Policy Page ends -->

    <!-- Download app section starts -->
    <section class="download-app-layout-1" hidden>
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="download-entry">
              <h2>Everthing on the go in your pocket</h2>
              <p>
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true
                generator on the Internet.
              </p>

              <div class="app-download">
                <a href="#" class="btn-download btn-apple"
                  ><i class="fa fa-apple"></i> <span>Download on the</span>App
                  Store</a
                >
                <a href="#" class="btn-download btn-android"
                  ><i class="fa fa-android"></i> <span>Get in on</span>Google
                  Play</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Download app section ends -->
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";
import $ from 'jquery'
// import jquery from 'jquery';
export default {
  name: "ContactPage",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    show_policy(num) {
     
 const elements = document.querySelectorAll(`.privacy_conts`);
        elements.forEach(element => {
        element.style.display = 'none';
    });
    $(`.cont${num}`).show(500)
    }
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style scoped>
/* .howit_vid {
  height: 400px;
  width: 400px;
  margin-top: -80px;
} */
.fimg {
  height: 250px;
  width: 400px;
}
.card_price {
  font-size: 18px;
}
.contact-single {
  height: 180px;
}
.page-header {
  background-image: url(../assets/images/stella.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;
  background-color: #14a79e;
  height: 440px;
}

#select {
  padding: 0px;
  padding-left: 10px;
}
.min_head {
  display: block;
  font-weight: bolder;
  margin-top: 10px;
  margin-bottom: 10px;
}

.writeup_list{margin-top: 10px;
  margin-bottom: 10px;}
  .privacy_links:hover{color:#14a79e;cursor: pointer;}
.privacy_conts{display: none;}
.cont1{display:block;}

@media (min-width: 300px) and (max-width: 800px) {
  .page-header {
    height: 100px;
  }
  .contact-single {
    height: 140px;
    padding: 10px;
  }
  .mt {
    margin-top: -50px;
  }
}
</style>

<template>
  <div id="app">
    <!-- Preloader starts -->
    <div class="preloader">
      <div class="loader">
        <div class="diamond"></div>
        <div class="diamond"></div>
        <div class="diamond"></div>
      </div>
    </div>
    <!-- Preloader Ends -->
  <HeaderPlugin />
    <router-view />
    <FooterPlugin />
  </div>
</template>

<script>
import HeaderPlugin from "./components/Plugin/Header";
import FooterPlugin from "./components/Plugin/Footer";

export default {
  name: "App",
  components: {
    HeaderPlugin,
    FooterPlugin
  },
  data() {
    return {
      // isLoading: true
    };
  },
  mounted() {},
};
</script>
<style>
body {
  scrollbar-width: 10px;
  background: white;
}
</style>

<template>
  <div class="ccontainer">
    <!-- Page Header Section Starts -->
    <section class="page-header">
      <div class="container">
        <div class="row" hidden>
          <div class="col-lg-12">
            <!-- Page Header start -->
            <div class="page-header-title wow fadeInUp">
              <h1>Terms and Conditions</h1>
              <p>Last updated : 20th May, 2024</p>
            </div>
            <!-- Page Header start -->
          </div>
        </div>
      </div>
    </section>
    <!-- Banner Section Ends <div class="row" style="text-align: center;margin-top: 100px;margin-bottom: -80px">-->

    <!-- Terms and Conditions starts -->
	
    <div class="page-privacy-policy">
		
      <div class="container">
		<div class="page-header-title text-center" data-aos="fade-up">
		 <h1>Terms and Conditions</h1>
              <p>Last updated : 20th May, 2024</p>
			  </div>
        <div class="row">
          <div class="col-md-3">
            <div class="privacy-sidebar">
              <div class="privacy-list">
                <ul>
                  <li class="privacy_links" @click="show_policy(1)">Introduction</li>
                  <li class="privacy_links" @click="show_policy(2)">General </li>
                  <li class="privacy_links" @click="show_policy(3)">
                    Registration
                  </li>
                  <li class="privacy_links" @click="show_policy(4)">
                    Minors
                  </li>
                  <li class="privacy_links" @click="show_policy(5)">
                    
                      User Data
                  </li>
                  <li class="privacy_links" @click="show_policy(6)">Removal Of Content</li>
                  <li class="privacy_links" @click="show_policy(7)">Third-Party Services </li>
                  <li class="privacy_links" @click="show_policy(8)">No Medical Advice</li>
                  <li class="privacy_links" @click="show_policy(9)">
                    Communications
                  </li>
                  <li class="privacy_links" @click="show_policy(10)">Ownership</li>
                  <li class="privacy_links" @click="show_policy(11)">Violations</li>
                  <li class="privacy_links" @click="show_policy(12)">Termination</li>
                  <li class="privacy_links" @click="show_policy(13)">Limitation Of Liability</li>
                  <li class="privacy_links" @click="show_policy(14)">Disclaimers </li>
                  <li class="privacy_links" @click="show_policy(15)">Indemnity </li>
                  <li class="privacy_links" @click="show_policy(16)">Assumption of Risk  </li>
                  <li class="privacy_links" @click="show_policy(17)">Severability </li>
                  <li class="privacy_links" @click="show_policy(18)">Waiver </li>
                  <li class="privacy_links" @click="show_policy(19)">Electronic Contracting  </li>
                  <li class="privacy_links" @click="show_policy(20)">Amendments </li>
                  <li class="privacy_links" @click="show_policy(21)">Electronic Contracting  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <div class="privacy-content">
              <div class="privacy_conts cont1">
              <h3>1. Introduction</h3>
              <p>
               StellaSync (unifying health records, transforming lifes) is owned and managed by StellaSync LTD. These Terms and Conditions written on this webpage shall manage your use of our service.
                These Terms and Condition (these "Terms") apply to users of StellaSync ("we" "us" or "our") also these Terms of Service describe the legally binding terms and conditions on 
                which StellaSync LTD and its affiliates, StellaSync provides the information, data, features and services available on or through our <b>www.stellasync.com</b> site (the “Site”) or any related online or mobile function or application (together with the Site, the “Service”) to you, the users of the Service (“you” or “User”). 
<br>
Please carefully go through these terms and conditions and the privacy policy available at site before you decide to access the Website or avail the services made available on the Website and on the mobile app because by registering and using our Services you hereby confirm that all provided details are correct and that you are the legal/authorized user and/or owner/business partner/beneficiary representing the medical firm such as clinic, center, hospital, medical lab, pharmacy. You are also entering into a legal binding agreement (“Agreement”) with StellaSync and agree to comply with all of the terms and conditions hereof. If you do not agree to these terms and conditions, please do not use the Service.

              </p>
</div>
              <div class="privacy_conts cont2">

              <h3>General</h3>
              <p>
              This service will allow you to:
Securely and with confidentiality measurement taken: Have access to and view your medical firms’ data including patients’ details, appointments and procedures online.
By registering to this service, you confirm and acknowledge the following: 


<ul class="writeup_list">
    <li>You are the owner and/or the authorized person/staff representing the medical firm you are registering with.</li>
    <li>You will no longer use this service in the event that you no longer have legal ties to this medical firm.</li>
    <li>You will use StellaSync EMR and HMS legally and officially and you agree to the products terms and conditions.</li>
    <li>All details you have provided are correct and verified.</li>
    <li>StellaSync, will mirror your medical details (like appointments, basic patients’ detail, schedules, payments …etc.) online, anytime and from anywhere for your cloud access purpose only. By accessing StellaSync, you agree to allow StellaSync to have access to your data and upload them to the service for your view and service’s purposes usage only.</li>
    <li>Owner’s and/or main admin (medical firm’s official administrator) will be physically verified by phone” by a representative through the service before granting them access through an encrypted SMS code sent to their own mobile number.</li>
    <li>Adding additional system/service users will be managed and handled by main medical firm’s admin. The medical firm is responsible on adding users and managing user roles/permissions. Users should not be added unless the permission is verified and approved internally by medical firm’s management</li>
    <li>Owner’s and/or main admin or Users will not share their medical firm data with anyone, except for medical firm approved by StellaSync.</li>
    <li>You have the option to request termination/de-activate your access to StellaSync at any time. This can be requested by sending us an email on support@stellasync.com.</li>
    <li>You will have no access to StellaSync if you failed to pay any outstanding fees under your billing for StellaSync service or any dues/outstanding.</li>
    <li>If needed, this service can be stopped, suspended or terminated without prior notice to users and with no obligations to provide any justifications.</li>
    <li>StellaSync will decide on the costing policy and prices. You understand that all prices are subject to change anytime including VAT and other additional taxes or changes.</li>
    <li>It is your responsibility (Owner’s and/or main admin) to manage users who access the service once access is granted to You.</li>
    <li>StellaSync service is free for 1 user per one clinic/center/maternity/hospital license, additional fees are applied for extra users.</li>
    <li>StellaSync service is generally free; however, the fees are subject to review after 3 months of user creation or at any time without prior notice. Prices are also subject to VAT (value added tax) addition at any time if required by the Government of Nigeria. Access will be immediacy de-activated/stopped if outstanding payment is not cleared.
</li>
    <li>You are aware this service is secure through SSL.</li>

  </ul>
              </p>
</div>
              <div class="privacy_conts cont3">
              <h3>Registration</h3>
              <p>
			 In order to use our Service, you may need to create an account and register with us. If you register as a User or otherwise use the Service, you represent and warrant to StellaSync that: 


<ul class="writeup_list">
    <li>You are of legal age to form a binding contract and that you are a legal/authorized user and/or owner/business partner/beneficiary representing the medical firm  and, if you are using the Service on behalf of your employer or another organization, you have the right to enter these Terms on behalf of 
      such organization and to bind such organization to these Terms (in which case, for clarity, the terms “you” and “User” herein will include both you, the individual user, and such organization). </li>
    <li>You will provide StellaSync with accurate, current and complete registration information.</li>
    <li>Your registration and your use of the Service is not prohibited by law. </li>
   
</ul>
You (or, if applicable, your organization) are responsible for your registration and all use of the Service under it. Your registration, and these Terms, is personal to you, and is not transferable by you to any third party without the prior written consent of StellaSync.
You may not access the Service if you are, or are acting on behalf of, a direct competitor of ours, except with our prior written consent. You may not access the Service for purposes of monitoring the availability, performance, or functionality of the Service, or for any other competitive benchmarking purpose.
<br>
To the extent applicable, you are responsible for taking all reasonable steps to ensure that no unauthorized person shall have access to your password or account (if any is set up with StellaSync). It is your sole responsibility to; 

<ul class="writeup_list">
    <li>Control the disclosure and use of your user’s name, password and pin.</li>
    <li>Authorize, monitor, and control access to and use of your account password and pin.</li>
    <li>Promptly inform your employer, organization, provider, or admin, as applicable, of any need to deactivate a sign-in credential and/or password. Sharing passwords with any other person, including others in your own organization is not permitted. You agree not to provide your sign-in credentials in a manner that allows for any use of data mining, robots, or similar data gathering and extraction tools or any downloading or copying of account information for the benefit of another party. You shall promptly notify StellaSync if any password/pin is lost, stolen, or otherwise compromised, in order that StellaSync may deactivate the password.
</li>
</ul>

<span class="min_head">Minors</span>
Our Service is not directed at users under the age of 18. You must be 18 years of age or older to register, use the Services, or visit or use the website or mobile application in any manner. By registering, visiting, and using the website or mobile app or accepting this Agreement, you represent and warrant to StellaSync that you are 18 years of age or older and that you have the right, authority, and capacity to use the Services available through the website or mobile app and agree to and abide by this Agreement.
<br>
StellaSync does not knowingly collect or maintain personally identifiable information from persons under 18 years of age, and no part of the Service is directed at persons under 18. If StellaSync discovers the collection of personally identifiable information from individuals under 18 years old without verifiable parental consent, it will promptly delete the information. If you are a minor's parent or legal guardian You may use the Services on behalf of an unemancipated minor. By doing this, you certify that you have the authority to act on behalf of the minor in question, and you acknowledge and concur that, in that capacity, all provisions of these Terms that apply to you also apply to the minor. To make such a request, please contact us at support@stellasync.com

</p>
</div>
            
              <div class="privacy_conts cont4">

              <h3>Service Restrictions</h3>
              <p>
				We share personal information with:
You warrant and agree that your use of the Service will be consistent with these Terms, and will not infringe or violate the rights of any other party or breach any contract or legal duty to any other parties, and will not interfere, harm, or hamper the security, stability or integrity of our Service. You agree to ensure the confidentiality and availability of information held and exchanged on our Services. You will comply with the applicable laws, regulations and ordinances relating to the Service. You are responsible for obtaining and maintaining the computer and other equipment you use to access the Service, and for paying for such equipment and any telecommunications charges. We are not liable for any loss or damage you suffer arising from damage to equipment used in connection with use of the Service.
<br>
You shall comply with all rules and policies for the use of the Service set forth on the Service, which are hereby incorporated into these Terms. Without limitation, you shall not:
<ul class="writeup_list">
    <li>Upload, post, submit or distribute any User Data, or otherwise transmit to the Service any materials, containing any software viruses or any other code, file or program that is designed to interrupt, destroy or limit the functionality of any computer software, hardware or telecommunications equipment.</li>
    <li>Access, use, or disseminate our Services or any information or files accessible through our Services in a way that violates any applicable law or regulation or the rights of any individual or entity.</li>
    <li>Except as otherwise permitted by these Terms, Users can not copy, take or otherwise collect information about others, without StellaSync’s consent.</li>
    <li>Distribute, sell, transfer, or otherwise make available any information or user data obtained through our Services to any third party, or use such information to market any good or service, including by sending or enabling the sending of unsolicited emails or so-called "spam."</li>
    <li>Use manual or automated software, devices, or other processes to “crawl”, “scrape” or “spider” any portion of the Service or otherwise to copy, obtain, propagate, distribute or misappropriate any User Data.</li>
    <li>Disable, defeat, avoid, remove, deactivate, or in any other way get around any technical safeguards we've put in place to keep our Services stable or to maintain the confidentiality, integrity, or accessibility of any information, content, or data hosted or housed on our Services.</li>
    <li>Remove any copyright, trademark, or other property rights notices that are included in or on our Services.</li>
    <li>Probe, scan, or test the security of our services or the system or network that supports them, or get over any security or authentication controls.</li>
    <li>Copy, download, index, or publish any part of our Services.</li>
    <li>Introduce any software, code, or other item that in any way (i) enables unauthorized access to our systems or any software, hardware, files, or data stored on them, (ii) disables or damages or otherwise adversely affects the operation of our systems or any software, hardware, files, or data stored on them, or (iii) overburdens or otherwise interferes with the proper operation of our Services.</li>
    <li>Engage in any other conduct that interferes with the Service or that restricts or inhibits any other person from using or enjoying the Service, or which, in StellaSync’s sole judgment, exposes StellaSync or any of their directors, employees or agents to any liability or detriment of any type.
</li>
</ul>
StellaSync reserves the right (but is not obligated) to monitor use of the Service in order to ensure that Users comply with these Terms and applicable law, and to impose limitations or restrictions on any User’s use of the Service should StellaSync determine in its sole discretion that there may be a violation of these Terms or other legitimate business need to do so.
 </p>
              </div>
             
              <div class="privacy_conts cont5">
              
              <h3>User Data</h3>
              <p>
 In connection with using the Service, you are responsible for your User Data and any information, opinions, advice, and other content therein, and its accuracy, legality, and reliability. StellaSync is under no obligation to edit or control User Data that you upload, post, submit or distribute, and will not be in any way liable or responsible for User Data. The User Data remains your property, and StellaSync does not claim any ownership of the copyright or other proprietary rights in such User Data, provided that, to the extent that any User Data is generally publicly available, StellaSync will not be prevented by these Terms from any use of such User Data. You agree that: 

<ul class="writeup_list">
    <li>you hereby grant and agree to grant StellaSync a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, transferable license, with right to sublicense through multiple tiers, to copy, edit, modify, use, publish, distribute, prepare derivative works, publicly perform, publicly display and otherwise exploit in any manner the User Data in connection with operation of the Service, promotion of the Service, and any other purposes reasonably related to the development and operation of the Service, StellaSync’s other products, software or services.
</li>
    <li>StellaSync shall in no event be liable to you for any use or misuse of your User Data by any third party;</li>
    <li>You represent and warrant that you own all proprietary rights in your User Data or, with respect to any User Data you do not own, you have the full authority and right to upload, post, submit or distribute the User Data, as applicable, and to grant the licenses granted hereunder, and that your uploading, posting, submitting or distributing of the User Data, as applicable, and the exercise by StellaSync and other users of the license rights granted by you shall not infringe any third party intellectual property rights, nor violate any rights of privacy or publicity, nor be defamatory, libelous, vulgar, profane or obscene, nor violate any law or other right, privilege or interest of any third party.
</li>
    <li>If any royalties are payable to any third party for exploitation of User Data uploaded, posted, submitted or distributed by you in accordance with the licenses granted by you in these Terms, you shall be responsible for and pay such royalties</li>
    <li>You agree not to upload, submit, publish or otherwise make available on or through the Site any content or material that is illegal, fraudulent, harassing, abusive, defamatory, obscene, vulgar, sexually explicit, profane, threatening, invasive of privacy, infringing of intellectual property rights, harmful, hateful or racially or ethnically objectionable. You are solely responsible for any content or material that you upload, submit, publish or otherwise make available on or through the Site. We have no responsibility to monitor the Site for inappropriate content or conduct, but we reserve the right to review, monitor and remove any such content in our sole discretion. Your use of the Site is subject to existing laws and legal process, and nothing contained in these Terms or on the Site constitutes a waiver of any right we may have under applicable law. StellaSync has the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or behavior of anyone using the Site</li>
  
</ul>
  Your details and details related to your medical firm are confidential too, all shall not be shared with anyone/entity.
<br>
All users of the Service access and use User Data at their own risk, and StellaSync will not be liable for any loss or damage that any user may suffer arising from User Data. StellaSync reserves the right to monitor, edit, modify, delete, reformat, remove, excerpt, translate or disclose any User Data, subject always to the StellaSync Privacy Policy, but StellaSync is under no obligation to do any of these things. Please keep in mind that the Service is not a backup service and you should store copies of User Data in other locations.
<br>
In addition to and without limiting any other rights herein (including in the Privacy Policy), you also grant us the rights to (i) de-identify your User Data (i.e., to remove your name and other identifying characteristics, consistent with applicable laws and regulations), and (ii) use or disclose de-identified data in any manner for any purpose.
To the extent that your User Data constitutes protected health information subject to any regulation, rule or standards issued thereunder, then StellaSync’s rights and obligations with respect thereto shall also be governed by applicable law and the terms and conditions of any applicable to which StellaSync is a party. 

</p>
</div>
              <div class="privacy_conts cont6">
<h3>Removal Of Content</h3>
<p>
The content provided on StellaSync serves solely for informational purposes only and should not be relied upon for making legal, financial, or other critical decisions. Moreover, we do not warrant the accuracy, timeliness, or completeness of the content. You acknowledge and agree that while StellaSync reserves the right to monitor, modify, or remove any content at its discretion at any time, it is not obligated to do so. Nevertheless, any delay or failure to remove any content does not attribute or mean it’s our fault.
</p> </div>          
       <div class="privacy_conts cont7">
<h3>Third-Party Services </h3>
              <p>
              StellaSync service may be dependent on, interact with, or even enable access to a third party’s services or websites (each referred to as a "Third-Party Service"), each of which may have its own separate terms of use. The use of each Third-Party Service may need your acceptance to their terms of use. As such, you are required to adhere to the applicable terms of use when utilizing both the Third-Party Service and our service. However, such dependency, integration, interaction, or access does not imply endorsement, sponsorship, or affiliation with the Third-Party Service by StellaSync. StellaSync explicitly disclaims any liability or responsibility to you or any other individual for any Third-Party Services. Before engaging in any transactions with or through these Third-Party Services, it is advisable to conduct your own investigation as you see fit and read their terms and conditions.

              </p>
</div>
              <div class="privacy_conts cont8">

              <h3>No Medical Advice </h3>
              <p>
              Nothing in our Services should be interpreted as a medical diagnostic or treatment. The Service does not provide medical advice and neither the information available nor any email responses to your questions shall create a physician-patient, pharmacist-patient relationship or constitute the practice of medicine or clinical pharmacy. The information available from the Service and from any third party should not be used as a substitute or supplement for professional medical advice. If you have medical or health-related questions, contact your physician. You should always contact your physician before stopping, starting, or modifying your use of any medication.
              </p>
</div>
              <div class="privacy_conts cont9">

              <h3>Communications</h3>
              <p>
             You are connecting with StellSync electronically if you register for or log in to our Services, send us an email, or use our instant messaging service. StellSync, along with a few third-party service providers, collect information when you visit our websites. In each situation, our StellaSync Privacy Policy, governs the type of information we collect, how long and in what way we preserve it, how we use it, and to whom we disclose it.

<br>
We sometimes have to send you specific communications, such administrative messages and service notifications, as part of providing our Services to you. It's possible for us to communicate with you via email, instant message, a posting on one of our services, or another channel. You give us permission to send you these communications electronically. Our responses to you or our Service announcements and administrative messages we provide you are considered part of the Services themselves, from which you may not be able to opt out, if you have provided information to us through the Services or registered for the Services.
<br>
You acknowledge and agree that all agreements, notices, consents, disclosures, and other communications that we provide to you electronically, as well as any acceptances, agreements, consents, or authorizations that you provide to us electronically, will satisfy any and all formal requirements of writing, including any applicable laws.
              </p>
</div>
              <div class="privacy_conts cont10">

              <h3>Ownership</h3>
<p>You retain ownership of the intellectual property rights for the content you provide to our services. By uploading content to our services, you grant us and our partner companies the right to store, host, reproduce, publish, publicly perform, display, use, and distribute your content as outlined in these Terms and the StellaSync Privacy Policy. Additionally, we may translate, adapt, reformat, and create derivative works based on your content.
<br>
If you reach out to us with any ideas, suggestions, or proposals (collectively referred to as "Suggestions") about our products, services, or other items through various means such as "Contact Us," email, or other communication channels, or by speaking with our customer support or staff members. With regards to these suggestions, please note the following: (1) We do not have any obligation to keep them confidential, whether explicitly or implicitly; (2) We have the freedom to use or disclose these suggestions as we see fit, or choose not to disclose them at all; (3) These suggestions automatically become our property without any obligations on our part; and (4) You are not entitled to any form of accounting, remuneration, or reimbursement from us.
<br>
Regarding ownership, it is important to note that we and our licensors maintain sole ownership of the Content, the Services, and its structure, organization, and arrangement. However, your Content is an exception to this. Usage of our Services or any Content is strictly prohibited in any manner, such as reproduction, modification, creation of derivative works, distribution, sale, transfer, public performance, or public display, with the exception of the limited specific rights we have expressly granted you above.

</p>
</div>
 <div class="privacy_conts cont11">
 <h3>Violations</h3>
<p>Violations of this policy may result in a range of actions, including, without limitation, any or all of the following:
<br>
<ul class="writeup_list">
    <li>Suspension and eventual termination of the Account </li>
    <li>Pursuit of criminal charges  </li>
</ul>
Any and all uses of our Services may be monitored, and we reserve the right to look into any alleged violations of these Terms, our rights or interests, or the rights or interests of any third party.
<br>
We reserve the right, to the extent permitted by law, to collaborate with government agencies or third parties investigating potential illegal activities, harm to individuals or entities, or infringement upon their rights, within the limits permitted by law. We are committed to working closely with law enforcement officials and the individual in danger. For example, if a user makes threats of physical harm towards another user or anyone else, we will fully cooperate. We kindly request your authorization to collaborate with you on this investigation.

</p>
</div>

<div class="privacy_conts cont12">
 <h3>Termination</h3>
<p>StellaSync reserves the right to suspend and/or or terminate your access to our Service at any time, for any reason, and without prior notice or liability to you. You understand and agree that the license to access and use this Site and its Services remains valid until it is terminated. Termination of this license will occur in accordance with the Terms and Conditions outlined herein, or if you fail to adhere to any term or condition specified in these Terms of Use. 
<br>
StellaSync reserves the right to modify these Terms, terminate the Service or modify or discontinue any features or aspects of the Service, or modify its policies at any time. Without limitation of other means of notice, publication of updated Terms will constitute sufficient notice of an amendment to these Terms, and your continued use of the Service after such notice will constitute consent to the amendment. If you use the Service, you shall be bound by the version of the Agreement in effect at the time of your use. You are under an obligation to review the current version of these Terms and other published StellaSync policies before using the Service. In the event that these Terms are terminated or expire, Sections 10 through 21 remain in effect.
<br>
In the case of a jurisdiction that restricts limitation clauses, this limitation shall be applied to the greatest extent permitted by law. Nothing in these terms of use is intended to limit any rights you may have that may not be lawfully terminated.

</p>
</div>
<div class="privacy_conts cont13">
 <h3>Limitation Of Liability</h3>
<p>To the fullest extent allowed by applicable law, under no circumstances shall we or any of our subsidiaries or affiliates, or our respective directors, employees, agents, representatives, partners and licensors (collectively, the “StellaSync entities”) be liable for any special, incidental, indirect, consequential, or exemplary damages, including, but not limited to, loss of revenues or profits, loss of use, loss of goodwill/reputation or loss of information, however caused and whether based on contract, warranty, negligence, strict liability, or any other theory of liability, even if we have been apprised of the possibility or likelihood of such damages. Notwithstanding anything in these terms to the contrary, our aggregate liability in respect of any claim or action you may bring against us or any of the StellaSync entities, regardless of form of action or theory of liability, shall be limited to (a) N10,000 (ten thousand naira only) and (b) the amount you paid to us into your StellaSync account giving rise to such claim or action. you acknowledge that you may be waiving rights with respect to claims that are unknown or unsuspected. Accordingly, you agree to waive the benefit of any law, including, to the extent applicable law. You acknowledge and agree that your only right concerning any problems or dissatisfaction with the services is to request for termination of your account and/or discontinue any use of the services.
</p>
</div>
   <div class="privacy_conts cont14">
 <h3>Disclaimers </h3>
<p>StellaSync provides the site and services "as is," and “as available” and we hereby expressly disclaim all representations, claims, and warranties of any kind, whether expressed, implied, statutory or otherwise, this includes, but is not limited to, the implied warranties of performance, non-infringement, merchantability, or fitness for a particular purpose and title. StellaSync does not guarantee that the services, site, or their functions will be available, accessible, uninterrupted, timely, secure, accurate, complete, or error-free. There is no assurance that defects, if any, will be corrected or that the site and/or server are free of harmful components such as malware. Users acknowledge that any risks associated with the use or performance of the site and services are entirely theirs to bear, to the fullest extent permitted by applicable law. Therefore, StellaSync shall not be liable for any loss, liability, or damage the user may incur as a result. If a user is dissatisfied with our services or any content, your sole and exclusive remedy is to discontinue using and accessing our services.
</p>
</div>

<div class="privacy_conts cont15">
 <h3>Indemnity</h3>
<p>User agree to indemnify, defend, and hold harmless StellaSync, parent company, shareholders, subsidiaries, affiliates, partners, employees, its licensors and other service providers (collectively, the "Indemnifying Parties") from and against any and all claims, actions, proceedings, and suits, and all related liabilities, damages, settlements, penalties, fines, costs, and expenses (including, without limitation, any other dispute resolution expenses) incurred by any Indemnified Party arising from or related to User’s use of the Service, provision of User Data, or User’s breach of any provision of these Terms. StellaSync reserves the right (which it may delegate to its licensors in whole or part) to assume the sole control of the defense and settlement of any claim, action, suit or proceeding for which you are obliged to provide indemnification hereunder. You will cooperate with StellaSync (or such licensors, as applicable) with respect to such defense and settlement.
<br>
Without limitation of the foregoing, if we receive a subpoena or similar requirement to disclose your User Data issued by any court or governmental authority, and we are not a party to the proceeding in question, you will reimburse us for our reasonable costs and expenses of complying with such subpoena, including time spent by our personnel and our attorneys at time and materials rates.
</p>


<div class="privacy_conts cont16">
 <h3>Assumption of Risks</h3>
<p>Note that you bear full responsibility for your use of our services, as well as any content you provide to us. You understand and accept that your use and access to our services, along with the information and content within them, as well as any data transmitted through our services, are entirely at your own risk. This is in addition to the other risks we have already disclosed to you in these terms. As a result, we cannot be held responsible or we do not assume any liability to you for any consequences that may arise from your actions, including but not limited to the publication of any content you contribute or our use of the rights you give to us in relation to such content.
</p>
</div>
</div>


<div class="privacy_conts cont17">
 <h3>Severability</h3>
<p>In the event that any provision or clause within these Terms and Conditions is considered unlawful, void or unenforceable in any jurisdiction, then that provision shall be construed to the extent necessary to make it valid and enforceable in such a manner as comes closest to preserving the intentions of such provision, and the remaining provisions shall remain in full force and effect.
</p>
</div>

<div class="privacy_conts cont18">
 <h3>Waiver</h3>
<p>No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or consent shall be in writing and signed by StellaSync. Any consent by StellaSync to, or a waiver by StellaSync of any breach by you, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.
</p>
</div>

<div class="privacy_conts cont19">
 <h3>Electronic Contracting </h3>
<p>Your use of our services includes the ability to enter into agreements and/or to make transactions electronically. You understand that your electronic submissions signify your agreement and intent to be bound by such agreements and transactions. Your agreement and intent to be bound by electronic submissions applies to all records relating to all transactions you enter into on the services, including notices of cancellation, policies, contracts, and applications.
</p>
</div>

<div class="privacy_conts cont20">
 <h3>Amendments</h3>
<p>We reserve the right to update or change our Services or the provisions outlined in these Terms and Conditions from time to time. We advise you to regularly review these Terms and Conditions for any changes. You acknowledge and agree that continuing to use our Services after any updates or changes to these Terms and Conditions constitutes your acceptance of the revised Terms. In addition to the above, should we make changes to these Terms and Conditions that significantly impact your use of the Services, we reserve the right to inform you of any updates which will be published and communicated to the users through email or updates on our website(s). The current version of the Terms shall govern and surpass all previous versions.
</p>
</div>

<div class="privacy_conts cont21">
 <h3>Governing Law and Jurisdiction</h3>
<p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at support@stellasync.com or call +234 (0) 810 296 5619.</p>
</div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Privacy Policy Page ends -->

    <!-- Download app section starts -->
    <section class="download-app-layout-1" hidden>
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="download-entry">
              <h2>Everthing on the go in your pocket</h2>
              <p>
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true
                generator on the Internet.
              </p>

              <div class="app-download">
                <a href="#" class="btn-download btn-apple"
                  ><i class="fa fa-apple"></i> <span>Download on the</span>App
                  Store</a
                >
                <a href="#" class="btn-download btn-android"
                  ><i class="fa fa-android"></i> <span>Get in on</span>Google
                  Play</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Download app section ends -->
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";
import $ from 'jquery'
// import jquery from 'jquery';
export default {
  name: "ContactPage",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    show_policy(num) {
     
 const elements = document.querySelectorAll(`.privacy_conts`);
        elements.forEach(element => {
        element.style.display = 'none';
    });
    $(`.cont${num}`).show(500)
    }
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style scoped>
/* .howit_vid {
  height: 400px;
  width: 400px;
  margin-top: -80px;
} */
.fimg {
  height: 250px;
  width: 400px;
}
.card_price {
  font-size: 18px;
}
.contact-single {
  height: 180px;
}
.page-header {
  background-image: url(../assets/images/stella.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;
  background-color: #14a79e;
  height: 440px;
}

#select {
  padding: 0px;
  padding-left: 10px;
}
.min_head {
  display: block;
  font-weight: bolder;
  margin-top: 10px;
  margin-bottom: 10px;
}

.writeup_list{margin-top: 10px;
  margin-bottom: 10px;}
  .privacy_links:hover{color:#14a79e;cursor: pointer;}
.privacy_conts{display: none;}
.cont1{display:block;}

@media (min-width: 300px) and (max-width: 800px) {
  .page-header {
    height: 100px;
  }
  .contact-single {
    height: 140px;
    padding: 10px;
  }
  .mt {
    margin-top: -50px;
  }
}
</style>

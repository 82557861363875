<template>
  <div class="ccontainer">
    <!-- Page Header Section Starts -->
    <section class="page-header">
      <div class="container">
        <div class="row" hidden>
          <div class="col-lg-12">
            <!-- Page Header start -->
            <div class="page-header-title wow fadeInUp">
              <h1>Account Deletion</h1>
              <p>giving information on its origins</p>
            </div>
            <!-- Page Header start -->
          </div>
        </div>
      </div>
    </section>
    <!-- Banner Section Ends -->

    <!-- Account Deletion Page starts -->
    <div
      class="row"
      style="text-align: center; margin-top: 100px; margin-bottom: -80px"
    >
      <div class="col-lg-12 mt">
        <!-- Page Header start -->
        <div class="page-header-title" data-aos="zoom-in">
          <h1>Account Deletion</h1>
          <!-- <p>giving information on its origins</p> -->
        </div>
        <!-- Page Header start -->
      </div>
    </div>
    <div class="page-delete">
      <div class="row">
        <div class="col-md-4" data-aos="zoom-in" data-aos-delay="100">
          <div class="contact-single" style="background-color: transparent">
            <div class="icon-box">Step 1</div>
            <p>Click on profile icon and select account settings<br /></p>
            <img
              src="./../assets/images/delete_1.png"
              class="delete_img"
              data-aos="fade-left"
            />
          </div>
        </div>
        

		<div class="col-md-4" data-aos="zoom-in" data-aos-delay="100">
					<div class="contact-single" style="background-color: transparent;">
						<div class="icon-box">
							Step 2
						</div>
						<p>Click on delete account<br></p>
 <img
                src="./../assets/images/delete_2.png"
                class="delete_img" data-aos="fade-left"
              />
					</div>
				</div>
				  <div class="col-md-4" data-aos="zoom-in" data-aos-delay="100">
          <div class="contact-single" style="background-color: transparent">
            <div class="icon-box">Step 3</div>
            <p>Click on yes proceed<br /></p>
            <img
              src="./../assets/images/delete_3.png"
              class="delete_img"
              data-aos="fade-left"
            />
          </div>
        </div>
      </div>

	     <div class="row">
      
        <div class="col-md-4" data-aos="zoom-in" data-aos-delay="100">
          <div class="contact-single" style="background-color: transparent">
            <div class="icon-box">Step 4</div>
            <p>Select your reason and click on proceed<br /></p>
            <img
              src="./../assets/images/delete_4.png"
              class="delete_img"
              data-aos="fade-left"
            />
          </div>
        </div>

		<div class="col-md-4" data-aos="zoom-in" data-aos-delay="100">
					<div class="contact-single" style="background-color: transparent;">
						<div class="icon-box">
							Step 5
						</div>
						<p>Write a detail reason for exiting and click on confirmed account deletion<br></p>
 <img
                src="./../assets/images/delete_5.png"
                class="delete_img" data-aos="fade-left"
              />
					</div>
				</div>

				<div class="col-md-4" data-aos="zoom-in" data-aos-delay="100">
          <div class="contact-single" style="background-color: transparent">
            <div class="icon-box">Step 6</div>
            <p>Enter the OTP sent to your email account and click delete account<br /></p>
            <img
              src="./../assets/images/delete_6.png"
              class="delete_img"
              data-aos="fade-left"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Account Deletion Page ends -->

  <!-- Download app section starts -->
  <section class="download-app-layout-1" hidden>
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="download-entry">
            <h2>Everthing on the go in your pocket</h2>
            <p>
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks as necessary, making this the first true
              generator on the Internet.
            </p>

            <div class="app-download">
              <a href="#" class="btn-download btn-apple"
                ><i class="fa fa-apple"></i> <span>Download on the</span>App
                Store</a
              >
              <a href="#" class="btn-download btn-android"
                ><i class="fa fa-android"></i> <span>Get in on</span>Google
                Play</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Download app section ends -->
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";

export default {
  name: "ContactPage",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {
    AOS.init();
  },
};
</script>

<style scoped>
/* .howit_vid {
  height: 400px;
  width: 400px;
  margin-top: -80px;
} */
.fimg {
  height: 250px;
  width: 400px;
}
.card_price {
  font-size: 18px;
}
.contact-single {
  height: auto;
  background-color: transparent;
  
}
.page-header {
  background-image: url(../assets/images/stella.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;
  background-color: #14a79e;
  height: 440px;
}
.icon-box {
  font-size: 24px;
  color: #14a79e;
  font-weight: bolder;
}
#select {
  padding: 0px;
  padding-left: 10px;
}
.page-delete {
  margin-top: 140px;
  padding-left: 50px;
  padding-right: 50px;
}
.delete_img {
  height: 550px;
  width: 50%;
  margin-top: 10px;
}

@media (min-width: 300px) and (max-width: 800px) {
  .page-header {
    height: 100px;
  }
  .contact-single {
    height: auto;
    padding: 10px;
  }
  .mt {
    margin-top: -50px;
  }
  .delete_img {
  height: 450px;
  width: 100%;
  margin-top: 10px;
  display: block;
}
}
</style>
